import * as React from 'react';
import './css/home.css'
import { Button, Grid } from "@mui/material";
import { COLORS as Color } from "../Common/Constant/Color";
import OutlinedInput from '@mui/material/OutlinedInput';
import 'react-phone-input-2/lib/style.css';
import './css/career.css';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


const today = dayjs();
const yesterday = dayjs().subtract(1, 'day');
const todayStartOfTheDay = today.startOf('day');
const tomorrow = dayjs().add(1, 'day');

const Booking = () => {
    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [contact, setContact] = React.useState("");
    const [value, setValue] = React.useState(tomorrow);
    const SendButton = styled(Button)(({ theme }) => ({
        color: Color.dark,
        backgroundColor: Color.white,
        fontSize: 23,
        fontWeight: 'bold',
        textTransform: 'capitalize',
        border: `1px solid ${Color.dark}`,
        width: '100%',
        '&:hover': {
            backgroundColor: Color.dark,
            color: Color.white,
        },
    }));
    const [error, setError] = React.useState(null);
    const [bookingType, setBookingType] = React.useState('30 minutes');

    const errorMessage = React.useMemo(() => {
        switch (error) {
            case 'disablePast': {
                return 'Your date or time is not valid';
            }
            default: {
                return '';
            }
        }
    }, [error]);

    const handleChange = (event) => {
        setBookingType(event.target.value);
    };

    return (
        <div style={{
            fontSize: ' calc(10px + 2vmin)',
            fontFamily: 'Helvetica',
            marginTop: '80px'
        }}>
            <h1 style={{ color: Color.black, fontSize: 40, textTransform: 'uppercase', marginTop: '130px' }}>Get in Touch with us</h1>
            <h1 className="gradient-text" style={{
                fontSize: 40, margin: 0, fontWeight: 'normal', marginTop: 80
            }}>Ask Us Anything! </h1>
            <p>Fill Out the Form Below and We'll Be in Touch Soon.</p>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={5}
            >
                <Grid item
                    justifyContent="center"
                    alignItems="center"
                    sm={6} xs={10} style={{ textAlign: 'left' }}>
                    <label style={{ textAlign: 'left', fontSize: 18, color: Color.gray }}>Your Name</label>
                    <OutlinedInput size="small" style={textfieldStyle} value={name}
                        onChange={(e) => setName(e.target.value)} />
                    <label style={{ textAlign: 'left', fontSize: 18, color: Color.gray }}>Email</label>
                    <OutlinedInput size="small" style={textfieldStyle} value={email}
                        onChange={(e) => setEmail(e.target.value)} />
                    <label style={{ textAlign: 'left', fontSize: 18, color: Color.gray }}>Contact Number</label>
                    <OutlinedInput size="small" style={textfieldStyle} value={contact}
                        onChange={(e) => setContact(e.target.value)} />
                    <label style={{ textAlign: 'left', fontSize: 18, color: Color.gray }}>Your Booking</label>

                    <LocalizationProvider style={{ borderRadius: 20 }} dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                            <DateTimePicker disablePast onError={(newError) => setError(newError)}
                                slotProps={{ textField: { helperText: errorMessage, size: 'small', style: textfieldStyle } }}
                                value={value}
                                onChange={(newValue) => setValue(newValue)}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                    <label style={{ textAlign: 'left', fontSize: 18, color: Color.gray }}>Your Booking Type</label>
                    <FormControl sx={{ width: '100%' }} size="small">
                        <Select sx={textfieldStyle}
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={bookingType}
                            label="Age"
                            onChange={handleChange}
                            defaultValue={'30 minutes'}
                        >

                            <MenuItem value={'30 minutes'}>30 minutes</MenuItem>
                            <MenuItem value={'1 hour'}>1 hour</MenuItem>

                        </Select>
                    </FormControl>
                    <SendButton onClick={() => {
                        console.log("Clicked");
                    }}
                    >Book</SendButton>
                </Grid>
            </Grid>
        </div >
    )
}

const textfieldStyle = {
    border: 'double 1px transparent',
    backgroundImage: `linear-gradient(white, white),  linear-gradient(to right, ${Color.purple},${Color.grayBlue},${Color.lightBlue})`,
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',
    width: '100%',
    borderRadius: 10,
    margin: '5px 0 10px 0',
};
export default Booking;
