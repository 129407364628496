import React from 'react';
import { Button, Grid, Container, TextField, Box } from "@mui/material";
import { COLORS as Color } from "../Constant/Color";
import { styled } from '@mui/material/styles';


const SendButton = styled(Button)(({ theme }) => ({
    color: Color.white,
    backgroundColor: Color.dark,
    fontSize: 20,
    fontWeight: 'bold',
    textTransform: 'capitalize',
    border: `1px solid ${Color.dark}`,
    borderRadius:30,
    width: '30%',
    '&:hover': {
        backgroundColor: Color.dark,
        color: Color.white,
    },
}));
const ContactForm = () => {
    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [contact, setContact] = React.useState("");
    const [subject, setSubject] = React.useState("");
    const [requirement, setRequirement] = React.useState("");
    const [formData, setFormData] = React.useState({
        name: '',
        email: '',
        contactNo: '',
        subject: '',
        requirement: ''
    })

    const handleMailSend = () => {
        const { name, email, contactNo, subject, requirement } = formData

        const mailtoLink = `mailto:info@lyxux.com?subject=Free%20Quote%20Request&body=Name:%20${encodeURIComponent(
            name
        )}%0AEmail:%20${encodeURIComponent(email)}%0AContact%20Number:%20${encodeURIComponent(
            contactNo
        )}%0ASubject:%20${encodeURIComponent(
            subject
        )}%0ARequirement:%20${encodeURIComponent(requirement)}`

        window.location.href = mailtoLink;
    }

    return (
        <Container maxWidth="sm">
            <Box component="form" sx={{ mt: 4 }}>
                <Grid sx={{ textAlign: 'left' }} container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <label style={{ textAlign: 'left', fontSize: 18, color: Color.gray }}><b>Your Name</b></label>
                        <TextField
                            onChange={(e) => setName(e.target.value)}
                            size="small"
                            fullWidth

                            placeholder="Ex. Jone Alex"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <label style={{ textAlign: 'left', fontSize: 18, color: Color.gray }}><b>Email</b></label>
                        <TextField
                            onChange={(e) => setEmail(e.target.value)}
                            size="small"
                            fullWidth
                            placeholder="Ex. jone@example.com"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <label style={{ textAlign: 'left', fontSize: 18, color: Color.gray }}><b>Contact Number</b></label>
                        <TextField
                            onChange={(e) => setContact(e.target.value)}
                            size="small"
                            fullWidth

                            placeholder="Ex. 077xxxxxxx"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <label style={{ textAlign: 'left', fontSize: 18, color: Color.gray }}><b>Subject</b></label>
                        <TextField
                            onChange={(e) => setSubject(e.target.value)}
                            size="small"
                            fullWidth
                            placeholder="Subject"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <label style={{ textAlign: 'left', fontSize: 18, color: Color.gray }}><b>Message</b></label>
                        <TextField
                            onChange={(e) => setRequirement(e.target.value)}
                            fullWidth
                            placeholder="What can we help you with?"
                            variant="outlined"
                            multiline
                            rows={4}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SendButton onClick={() => {
                            window.location.href = `mailto:info@lyxux.com?subject=${subject}&body=${encodeURIComponent('Name: ' + name + ', Email: ' + email + ', Contact: ' + contact + ', Requirement: ' + requirement)}`;
                        }}
                        >Send</SendButton>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default ContactForm;
