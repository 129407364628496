import * as React from 'react';
import { Grid, Container, styled, Typography } from "@mui/material";
import main from "../assets/img/gallery/main.png";
import gallery1 from "../assets/img/gallery/gallery1.png";
import gallery2 from "../assets/img/gallery/gallery2.png";
import gallery3 from "../assets/img/gallery/gallery3.png";
import { COLORS as Color } from "../Common/Constant/Color";
import { useNavigate } from "react-router-dom";

const GradientTextSpan = styled('span')({
    background: '-webkit-linear-gradient(0deg, #7669EF 0%, #7669EF 45%, #51B2ED 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
});

const Gallery = () => {
    const galleries = [
        {
            url: gallery1,
            title: 'brand1',
            width: '20%',
        },
        {
            url: gallery2,
            title: 'brand2',
            width: '20%',
        },
        {
            url: gallery3,
            title: 'brand3',
            width: '20%',
        },
        {
            url: gallery1,
            title: 'brand1',
            width: '20%',
        },
        {
            url: gallery2,
            title: 'brand2',
            width: '20%',
        },
        {
            url: gallery3,
            title: 'brand3',
            width: '20%',
        },
    ];


    return (
        <Container style={{
            fontSize: ' calc(10px + 2vmin)',
            fontFamily: 'Helvetica',
            marginTop: '80px',
            color: Color.black
        }}>
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center" spacing={1} style={{ marginTop: "20px" }}>

                <Grid item xs={12}>
                    <div style={{ textAlign: 'center', padding: '0 10%' }}>

                        <Typography sx={{
                            fontSize:{xs:'30px', md:'55px'},
                            fontWeight:700,
                            textAlign:'center'
                        }}><GradientTextSpan>LYXUX World</GradientTextSpan></Typography>


                    </div>
                </Grid>
                <Grid item sm={6} xs={12} sx={{
                    textAlign: {
                        xs: 'center',
                        sm: 'left'
                    }
                }}
                >
                    <h2 style={{ textTransform: 'capitalize' }}>featured events</h2>
                    <img style={{width:'90%'}} src={main} alt="" />

                </Grid>
                <Grid item sm={6} xs={12} sx={{ textAlign: { xs: 'center', sm: 'left' } }}>

                    <h1 style={{ fontSize: 40, textTransform: 'capitalize', lineHeight: 1.5 }}>welcoming new members to the LYXUX family</h1>
                    <p style={{ fontSize: 18, textTransform: 'capitalize', lineHeight: 2 }} >On [Event Date], we welcomed many talented new members to the Lyxux family. This incredible gathering marked a significant milestone for our company, as we continue to grow and innovate in the luxury services industry.</p>


                </Grid>


            </Grid>

            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center" spacing={1} sx={{ marginTop:15 }}>
                {galleries.map((vector,index) => (
                    <Grid key={index} item xs={6} sm={4}>
                        <div style={{ textAlign: 'center', padding: '0 10%' }}>
                            <img style={{width:'90%'}} src={vector.url} alt="" />
                        </div>
                    </Grid>
                ))}

            </Grid>

        </Container>
    )
}
export default Gallery;
