import * as React from 'react';
import Carousel from 'better-react-carousel';
import { Grid, Container, Typography } from "@mui/material";
import blueIcon from "../assets/img/about us/blueIcon.png";
import value1 from "../assets/img/about us/value1.png";
import value2 from "../assets/img/about us/value2.png";
import value3 from "../assets/img/about us/value3.png";
import value4 from "../assets/img/about us/value4.png";
import brand1 from "../assets/img/home/ImrookaLogo.jpg";
import brand2 from "../assets/img/home/KadawathaHomes.jpg";
import brand3 from "../assets/img/home/crystalClear.png";
import brand4 from "../assets/img/home/Yegabiyo.jpg";
import brand5 from "../assets/img/home/esbLogo.png";
import brand6 from "../assets/img/home/ImrookaSpecieLogo.jpg";
import Vector1 from "../assets/img/about us/Vector1.png";
import Vector2 from "../assets/img/about us/Vector2.png";
import Vector3 from "../assets/img/about us/Vector3.png";
import Vector4 from "../assets/img/about us/Vector4.png";
import Vector5 from "../assets/img/about us/Vector5.png";
import Vector6 from "../assets/img/about us/Vector6.png";
import Vector7 from "../assets/img/about us/Vector7.png";
import Vector8 from "../assets/img/about us/Vector8.png";
import Vector9 from "../assets/img/about us/Vector9.png";
import Vector10 from "../assets/img/about us/Vector10.png";
import Vector11 from "../assets/img/about us/Vector11.png";
import Vector12 from "../assets/img/about us/Vector12.png";
import { COLORS as Color } from "../Common/Constant/Color";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Card from '@mui/material/Card';

const Events = () => {
    const brands = [
        {
            url: brand1,
            title: 'brand1',
            width: '20%',
        },
        {
            url: brand2,
            title: 'brand2',
            width: '20%',
        },
        {
            url: brand3,
            title: 'brand3',
            width: '20%',
        },
        {
            url: brand4,
            title: 'brand5',
            width: '20%',
        },
        {
            url: brand5,
            title: 'brand5',
            width: '20%',
        },
        {
            url: brand6,
            title: 'brand6',
            width: '20%',
        },
        
    ];
    const Vectors = [
        {
            url: Vector1,
            title: 'finance',
            width: '20%',
        },
        {
            url: Vector2,
            title: 'food',
            width: '20%',
        },
        {
            url: Vector3,
            title: 'hotel',
            width: '20%',
        },
        {
            url: Vector4,
            title: 'CRM',
            width: '20%',
        },
        {
            url: Vector5,
            title: 'real state',
            width: '20%',
        },
        {
            url: Vector6,
            title: 'Entertainment',
            width: '20%',
        },
        {
            url: Vector7,
            title: 'sports',
            width: '20%',
        },
        {
            url: Vector8,
            title: 'education',
            width: '20%',
        },
        {
            url: Vector9,
            title: 'visual graphics',
            width: '20%',
        },
        {
            url: Vector10,
            title: 'marketing',
            width: '20%',
        },
        {
            url: Vector11,
            title: 'healthcare',
            width: '20%',
        },
        {
            url: Vector12,
            title: 'ecommerce',
            width: '20%',
        },
    ];

    return (
        <div style={{
            fontSize: ' calc(10px + 2vmin)',
            fontFamily: 'Helvetica',
            marginTop: '80px',
            color: Color.black
        }}>
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center" spacing={1} style={{ marginTop: "20px" }}>

                <Grid item xs={12}>
                    <div style={{ textAlign: 'center', padding: '0 10%' }}>

                        <h1 className="gradient-text" style={{ fontSize: 40, }}>Who We Are</h1>
                        <p style={{ color: Color.headingColor, fontSize: 23, lineHeight: '40px', textTransform: 'capitalize', }}>LYXUX is a leading provider of innovative software solutions, combining expertise, creativity, and a client-centric approach to drive business growth and create exceptional user experiences.</p>
                        <img style={{ width: "80px" }} src={blueIcon} alt="" />

                    </div>
                </Grid>
            </Grid>


            <Container>
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center" spacing={1} style={{ marginTop: "50px" }}>

                    <Grid item xs={12}>
                        <div style={{ textAlign: 'center', padding: '0 10%' }}>

                            <h1 style={{ fontSize: 40, }}>Our Values.</h1>


                        </div>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <div style={{ textAlign: 'center', padding: '0 10%' }}>

                            <img style={{ width: "65%" }} src={value1} alt="" />
                            <h3>Quality</h3>

                        </div>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <div style={{ textAlign: 'center', padding: '0 10%' }}>

                            <img style={{ width: "65%" }} src={value2} alt="" />
                            <h3>Customer Focus</h3>

                        </div>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <div style={{ textAlign: 'center', padding: '0 10%' }}>

                            <img style={{ width: "65%" }} src={value3} alt="" />
                            <h3>Innovation</h3>
                        </div>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <div style={{ textAlign: 'center', padding: '0 10%' }}>

                            <img style={{ width: "65%" }} src={value4} alt="" />
                            <h3>Intergrity</h3>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ textAlign: 'center', padding: '0 10%' }}>

                            <Typography sx={{ textTransform: 'capitalize', fontSize: 18 }}>These four principles highlight our fundamental beliefs as a team and as a business. Our core values attract and unite individuals focused on building a stable, desirable work environment and providing exceptional solutions we are proud of.</Typography>


                        </div>
                    </Grid>
                </Grid>
            </Container>

            {/*start vision & mission*/}
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn"
                style={{ marginTop: '100px', }}>
                <Container>
                    <Grid container
                        direction="row"
                        justifyContent="left"
                        alignItems="stretch"
                        spacing={10}

                        style={{
                            background: Color.white,

                        }}>
                        <Grid item xs={12} sm={6} justifyContent="center"
                            alignItems="left">
                            <Card sx={{ textAlign: 'left' }}>
                                <Container>
                                    <h1>Our Vision</h1>

                                    <p style={{ fontSize: 18, lineHeight: 1.5, textTransform: 'capitalize' }}>At LYXUX, our vision is to be the leading provider of software worldwide, setting the standard for <b>innovation</b>, <b>quality</b>, and <b>customer satisfaction</b>. We aim to empower our customers by delivering solutions that enhance their lives and drive their success.</p>
                                </Container>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} justifyContent="center"
                            alignItems="center" >
                            <Card sx={{ textAlign: 'left', height: '100%' }}>
                                <Container>
                                    <h1>Our Mission</h1>

                                    <p style={{ fontSize: 18, lineHeight: 1.5, textTransform: 'capitalize' }}>Our mission is to revolutionize the software industry by <b>offering cutting-edge software services</b> that exceed expectations.</p>
                                </Container>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </AnimationOnScroll>
            {/*end vission & mission*/}

            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn"
                style={{ marginTop: '100px', }}>
                <Container>
                    <h1 style={{ color: Color.black, fontSize: 40, textTransform: 'capitalize' }}>our industry focus</h1>
                    <Grid container
                        direction="row"
                        justifyContent="center"
                        alignItems="center" spacing={1} style={{ marginTop: "20px" }}>
                        {Vectors.map((vector) => (
                            <Grid item xs={6} sm={3}>
                                <div style={{ textAlign: 'center', padding: '0 10%' }}>
                                    <img src={vector.url} alt="" />
                                    <p style={{ textTransform: 'capitalize' }}>{vector.title}</p>
                                </div>
                            </Grid>
                        ))}

                    </Grid>
                </Container>
            </AnimationOnScroll>
            <Grid>
                <Grid style={{ paddingBottom: '20px', marginTop: '100px' }}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={5}
                    >
                        <Grid item>
                            {/* <AnimationOnScroll animateIn="animate__fadeInRight"> */}
                            <h1 style={{ color: Color.black, fontSize: 40, }}>WHO WE WORK WITH</h1>
                            {/* </AnimationOnScroll> */}


                        </Grid>
                    </Grid>
                    {/* <AnimationOnScroll animateIn="animate__fadeIn" style={{width: '100%'}}> */}
                    <Container style={{
                        marginBottom: 10, width: '100%',
                    }}>
                        <Carousel cols={5} rows={1} gap={1} hideArrow={true} autoplay={1000} loop>
                            {brands.map((image) => (
                                <Carousel.Item>
                                    <img style={{ width: "80%" }} src={image.url} alt="" />
                                </Carousel.Item>
                            ))}

                        </Carousel>

                    </Container>
                    {/* </AnimationOnScroll> */}
                </Grid>
            </Grid>
        </div>
    )
}
export default Events;
