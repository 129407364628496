import React, { useState } from 'react';
import './css/about.css';
import './css/service.css';
import serv1 from "../assets/img/service/service1.png";
import serv2 from "../assets/img/service/service2.png";
import serv3 from "../assets/img/service/service3.png";
import serv4 from "../assets/img/service/service4.png";
import serv5 from "../assets/img/service/service5.png";
import serv6 from "../assets/img/service/service6.png";
import serv7 from "../assets/img/service/service7.png";
import serv8 from "../assets/img/service/service8.png";
import serv9 from "../assets/img/service/service9.png";
import { COLORS as Color } from "../Common/Constant/Color";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useNavigate } from "react-router-dom";
import ServiceCard from '../Common/Component/ServiceCard'
import { TroubleshootSharp } from '@mui/icons-material';
import { Grid, Typography, Button, Container, Box, Card, Chip, CardContent, ToggleButton, ToggleButtonGroup } from '@mui/material';


const Services = () => {
    const services = [
        {
            url: serv1,
            title: 'IOS development',
            description: 'LYXUX offers advanced iOS app development, utilizing the latest technologies and industry best practices to create captivating mobile experiences for Apple devices, delivering high-quality, engaging apps that drive success.',
        },
        {
            url: serv2,
            title: 'WEB development',
            description: 'Our web development solutions focus on creating visually stunning, user-friendly, and functional websites, ensuring an exceptional online experience for visitors through responsive design and seamless navigation.',
        },
        {
            url: serv3,
            title: 'mobile development',
            description: 'LYXUX offers advanced iOS app development, utilizing the latest technologies and industry best practices to create captivating mobile experiences for Apple devices, delivering high-quality, engaging apps that drive success.',
        },
        {
            url: serv4,
            title: 'digital marketing',
            description: 'LYXUX offers comprehensive digital marketing services, optimizing websites for search engines, enhancing social media engagement, and employing data-driven strategies for maximum ROI and continuous growth.',
        },


        {
            url: serv5,
            title: 'Enterprise solutions',
            description: 'LYXUX Enterprise Solutions streamline business operations, enhance collaboration, and drive growth by integrating seamlessly into existing systems, empowering organizations to reach their full potential.',
        },
        {
            url: serv6,
            title: 'blockchain development',
            description: 'LYXUX offers comprehensive services for blockchain technology, including development, smart contract implementation, and DApp creation, transforming business operations, enhancing data integrity, and facilitating secure transactions.',
        },
        {
            url: serv7,
            title: 'multimedia creations',
            description: 'LYXUX offers comprehensive digital marketing services, optimizing websites for search engines, enhancing social media engagement, and employing data-driven strategies for maximum ROI and continuous growth.',
        },
        {
            url: serv8,
            title: 'IOT solutions',
            description: 'LYXUX offers comprehensive services in IoT, focusing on device communication, architecture design, and data analytics to drive innovation, optimize operations, and embrace the digital revolution.',
        },
        {
            url: serv9,
            title: 'salesforce',
            description: 'Lyxux offers expert Salesforce solutions to boost your business. Our certified team specializes in Sales Cloud, Service Cloud, and Non-Profit Cloud services. We provide seamless integration, custom app development, and insightful analytics to help you optimize sales and enhance customer interactions. With our efficient and effective solutions, we help your business grow faster and smarter.',
        },
    ];
    const [selectedModel, setSelectedModel] = useState('Fixed Scope');
    const handleModelChange = (event, newModel) => {
        if (newModel !== null) {
            setSelectedModel(newModel);
        }
    };

    const models = [
        {
            title: 'Fixed Scope',
            description: `We set the project’s goals, deadlines, and budget with you and take full charge of its execution with your supervision. We assemble an expert team and collaborate with you on a fixed-scope/fixed-price model with precise deliverables.`,
            roles: 'UI/UX Designers • Developers • QA • Project Manager',
            duration: 'Short - Mid',
            flexibility: 'Fixed',
            engagement: 'Low',
        },
        {
            title: 'Dedicated Agile Team',
            description: `We give you the dream agile product team that would cost you a lot of time and frustration to assemble. We work with SCRUM, and you take the responsibilities of a product owner.`,
            roles: 'UI/UX Designers • Developers • QA • Project Manager',
            duration: 'Mid - Long',
            flexibility: 'Time & Material',
            engagement: 'Medium',
        },
        {
            title: 'Staff Augmentation',
            description: `We fill the missing competencies in your team and provide additional profiles to enhance your team. They seamlessly integrate with your team and workflow.`,
            roles: 'Developers • Designers • QA • DevOps',
            duration: 'Mid - Long',
            flexibility: 'Time & Material',
            engagement: 'High',
        },
    ];

    return (
        <div style={{
            fontSize: ' calc(10px + 2vmin)',
            fontFamily: 'Helvetica',
            marginTop: '80px',
            marginBottom: '20px',
            color: Color.black,
            overflow: 'hidden'
        }}>
            <Container>
                <Grid container
                    direction={'row'}
                    justifyContent="center"
                    alignItems="center" spacing={1} style={{ marginTop: "20px" }}>

                    <Grid item md={8} sm={6} xs={12} sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
                        <AnimationOnScroll initiallyVisible={true} animateOnce={true} animateIn={"animate__fadeInLeft"}>
                            <Typography sx={{ fontSize: { xs: '40px', sm: '40px', md: '80px' }, fontWeight: 'bold', fontFamily: 'Helvetica' }}>Our Services</Typography>
                            <p style={{
                                fontSize: 18, textTransform: 'capitalize', color: '#595959',
                                lineHeight: 1.7,
                                letterSpacing: '.03em',
                                marginTop: '20px'
                            }} >You have to be swift in responding to the ever-changing business requirements, and your tech team needs to enable that by shipping product updates quickly and regularly. That’s why our teams deploy lean and agile development techniques to maintain high development velocity, scalability, and flexibility of the codebase long-term.</p>
                            <div className="statistics-container">
                                <div className="stat-item">
                                    <span className="stat-number">120+</span>
                                    <span className="stat-label">Projects</span>
                                </div>
                                <div className="stat-item" style={{ transform: 'translate(-18px)' }}>
                                    <span className="stat-number">70+</span>
                                    <span className="stat-label">Developers</span>
                                </div>
                                <div className="stat-item" style={{ transform: 'translate(-36px)' }}>
                                    <span className="stat-number">90+</span>
                                    <span className="stat-label">Clients</span>
                                </div>
                            </div>
                        </AnimationOnScroll>
                    </Grid>

                    <Grid item md={4} sm={6} xs={12} sx={{
                        textAlign: {
                            xs: 'center',
                            sm: 'right',
                        }
                    }}
                    >
                        <AnimationOnScroll animateOnce={true} initiallyVisible={true} animateIn={"animate__fadeInRight"}>
                            <img style={{ width: '90%' }} src={serv1} alt="" />
                        </AnimationOnScroll>
                    </Grid>

                </Grid>

            </Container>
            <Grid container
                direction={'row'}
                justifyContent="center"
                alignItems="center" spacing={2} style={{ minHeight: '90vh', backgroundColor: '#0F0D25', color: 'white', fontFamily: 'Helvetica', marginTop: "20px", padding: '0 6%' }}>

                <Grid item lg={4} md={12} sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
                    <AnimationOnScroll initiallyVisible={true} animateOnce={true} animateIn={"animate__fadeInLeft"}>
                        <Typography sx={{
                            fontSize: { xs: '40px', sm: '40px', md: '56px' }, fontWeight: 800,
                            lineHeight: 1.25, fontFamily: 'Helvetica'
                        }}>Methodology</Typography>

                    </AnimationOnScroll>
                </Grid>

                <Grid item lg={8} md={12} sx={{
                    textAlign: {
                        xs: 'center',
                        sm: 'left',
                    }
                }}
                >
                    <AnimationOnScroll animateOnce={true} initiallyVisible={true} animateIn={"animate__fadeInRight"}>
                        <p style={{
                            textTransform: 'capitalize',
                            marginTop: '20px',
                            color: '#F5F5F5',
                            fontSize: '14px',
                            lineHeight: 1.7,
                            letterSpacing: '.03em'
                        }} >We implement a set of best practices for agile software development on each project like S.O.L.I.D principles, TDD with unit and feature testing, and maintain a self-explanatory clean code. With CI/CD pipelines, we automate and streamline the delivery of releases in a shorter time, while systematic peer reviews and pair programming help us maintain high code quality.</p>

                    </AnimationOnScroll>
                </Grid>
                <Grid item lg={4} md={12} sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
                    <AnimationOnScroll initiallyVisible={true} animateOnce={true} animateIn={"animate__fadeInLeft"}>
                        <Typography sx={{
                            fontSize: { xs: '40px', sm: '40px', md: '56px' }, fontWeight: 800,
                            lineHeight: 1.25, fontFamily: 'Helvetica'
                        }}>Approach</Typography>

                    </AnimationOnScroll>
                </Grid>

                <Grid item lg={8} md={12} sx={{
                    textAlign: {
                        xs: 'center',
                        sm: 'left',
                    }
                }}
                >
                    <AnimationOnScroll animateOnce={true} initiallyVisible={true} animateIn={"animate__fadeInRight"}>
                        <p style={{
                            textTransform: 'capitalize',
                            marginTop: '20px',
                            color: '#F5F5F5',
                            fontSize: '14px',
                            lineHeight: 1.7,
                            letterSpacing: '.03em'
                        }} >Breathing life into a product is a lean, iterative process. As your product evolves to better solve more hard-pressing user problems, you need to build and ship new features quickly and consistently. Our agile development teams enable you to do just that. We work with the Scrum framework to launch product updates consistently at the end of every sprint.</p>

                    </AnimationOnScroll>
                </Grid>

            </Grid>

            <Container sx={{ marginTop: 10 }}>
                <Grid container spacing={4} justifyContent="center">
                    {services.map((service, index) => (
                        <Grid key={index} item xs={12} md={6}>
                            <Card elevation={0} style={{ padding: '20px', border: 'none' }}>
                                <CardContent>
                                    <div style={{ textAlign: 'center', marginBottom: '20px', height: '200px' }}>
                                    <img style={{ height: '100%' }} src={service.url} alt="" />
                                    </div>
                                    <Typography variant="h5" component="div" gutterBottom style={{
                                        fontWeight: 800,
                                        lineHeight: 1.5,
                                        letterSpacing: '.03em', fontSize: '40px', textTransform: 'capitalize'
                                    }}>
                                        {service.title}
                                    </Typography>
                                    <Chip label="Frontend + Backend + Integrations" style={{
                                        marginBottom: '20px', border: '1px solid #D1D5DA',
                                        background: 'none', color: '#595959'
                                    }} />
                                    <Typography variant="body2" color="text.secondary">
                                        {service.description}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

            </Container>

            {/* <Container sx={{ marginTop: 10 }}>
                <Grid container
                    direction={'row'}
                    justifyContent="center"
                    alignItems="center" spacing={2} style={{ fontFamily: 'Helvetica' }}>

                    <Grid item lg={6} md={12} sx={{ textAlign: { xs: 'center', sm: 'left' } }}>

                        <Typography variant="h4" gutterBottom sx={{
                            fontWeight: 800,
                            lineHeight: 1.25, fontSize: '56px', fontFamily: 'Helvetica'
                        }}>
                            Engagement Models
                        </Typography>
                        <Typography variant="body1" gutterBottom sx={{
                            color: '#595959',
                            fontSize: '16px',
                            lineHeight: 1.7,
                            letterSpacing: '.03em',
                            marginTop: '24px'
                        }}>
                            Here are a few ways in which we collaborate with our current clients. Each model includes a different type of involvement on both sides, and we share responsibilities accordingly. See if you find one that suits your needs. If not, let’s talk about alternatives.
                        </Typography>
                    </Grid>
                    <Grid item lg={6} md={12} style={{
                        border: '1px solid rgba(217, 217, 217, 0.75)',
                        padding: '48px 36px',
                        borderRadius: '32px'
                    }}>
<ToggleButtonGroup
                            value={selectedModel}
                            exclusive
                            onChange={handleModelChange}
                            sx={{ mb: 2 }}
                        >
                            {models.map((model) => (
                                <ToggleButton
                                    sx={{
                                        backgroundColor: 'transparent',
                                        color: 'rgb(140, 140, 140)',

                                        fontWeight: 400,
                                        textTransform: 'none',
                                        transition: 'color 0.25s ease 0s',
                                        padding: '4px 16px 5px',
                                        border: selectedModel === model.title ? '1px solid #595959' : 'none',

                                        '&.Mui-selected': {
                                            borderColor: '#595959',
                                            backgroundColor: 'transparent',
                                            borderRadius: '100px',
                                        },
                                    }}
                                    key={model.title}
                                    value={model.title}
                                >
                                    {model.title}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>

                        <Grid container>
                            {models.map((model) => (
                                <Grid item xs={12} key={model.title}>

                                    {selectedModel === model.title && (
                                        <Box>

                                            <Typography variant="body2" sx={{
                                                fontSize: '14px',
                                                lineHeight: 1.7,
                                                letterSpacing: '0.03em', color: 'rgb(89, 89, 89)'
                                            }}>
                                                {model.description}
                                            </Typography>
                                            <Typography variant="subtitle2" sx={{
                                                mt: 2, fontSize: '14px',
                                                fontWeight: 600,
                                                lineHeight: 1.5,
                                                letterSpacing: '0.03em', color: 'rgb(89, 89, 89)'
                                            }}>
                                                {model.roles}
                                            </Typography>
                                            <hr style={{
                                                margin: '16px 0px',
                                                borderWidth: '0px 0px thin',
                                                borderStyle: 'solid',
                                                borderColor: 'rgba(209, 213, 218, 0.75)'
                                            }

                                            } />
                                            <Grid container direction={'row'}>
                                                <Grid item xs={4}>
                                                    <Typography sx={{
                                                        fontWeight: 400,
                                                        fontSize: '12px',
                                                        lineHeight: 1.5,
                                                        letterSpacing: '0.03em',
                                                        textTransform: 'capitalize', color: 'rgb(89, 89, 89)'
                                                    }} variant="caption" display="block">
                                                        Duration
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography sx={{
                                                        fontWeight: 400,
                                                        fontSize: '12px',
                                                        lineHeight: 1.5,
                                                        letterSpacing: '0.03em',
                                                        textTransform: 'capitalize', color: 'rgb(89, 89, 89)'
                                                    }} variant="caption" display="block">
                                                        Flexibility
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography sx={{
                                                        fontWeight: 400,
                                                        fontSize: '12px',
                                                        lineHeight: 1.5,
                                                        letterSpacing: '0.03em',
                                                        textTransform: 'capitalize', color: 'rgb(89, 89, 89)'
                                                    }} variant="caption" display="block">
                                                        Engagement
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography sx={{
                                                        fontWeight: 600,
                                                        fontSize: '14px',
                                                        lineHeight: 1.5,
                                                        letterSpacing: '0.03em',
                                                        textTransform: 'capitalize', color: 'rgb(89, 89, 89)'
                                                    }} variant="caption" display="block">
                                                        {model.duration}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography sx={{
                                                        fontWeight: 600,
                                                        fontSize: '14px',
                                                        lineHeight: 1.5,
                                                        letterSpacing: '0.03em',
                                                        textTransform: 'capitalize', color: 'rgb(89, 89, 89)'
                                                    }} variant="caption" display="block">
                                                        {model.flexibility}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography sx={{
                                                        fontWeight: 600,
                                                        fontSize: '14px',
                                                        lineHeight: 1.5,
                                                        letterSpacing: '0.03em',
                                                        textTransform: 'capitalize', color: 'rgb(89, 89, 89)'
                                                    }} variant="caption" display="block">
                                                        {model.engagement}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    )}
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Container> */}
        </div >
    )
}
export default Services;
