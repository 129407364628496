import * as React from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import Carousel from 'better-react-carousel';
import London from "../assets/img/contact/london.avif";
import Spain from "../assets/img/contact/Barcelona.avif";
import { Grid, Container, Link, Typography } from "@mui/material";
import ContactForm from './../Common/Component/ContactForm';
import { COLORS as Color } from "../Common/Constant/Color";
import front1 from "../assets/img/techStack/front1.png";
import front2 from "../assets/img/techStack/front2.png";
import front3 from "../assets/img/techStack/front3.png";
import back1 from "../assets/img/techStack/back1.png";
import back2 from "../assets/img/techStack/back2.png";
import back3 from "../assets/img/techStack/back3.png";
import back4 from "../assets/img/techStack/back4.png";
import mobile2 from "../assets/img/techStack/mobile2.png";
import mobile3 from "../assets/img/techStack/mobile3.png";
import mobile4 from "../assets/img/techStack/mobile4.png";
import db1 from "../assets/img/techStack/db1.png";
import db2 from "../assets/img/techStack/db2.png";
import db3 from "../assets/img/techStack/db3.png";
import cloud1 from "../assets/img/techStack/cloud1.png";
import cloud2 from "../assets/img/techStack/cloud2.png";
import cloud3 from "../assets/img/techStack/cloud3.png";
import cloud4 from "../assets/img/techStack/cloud4.png";
import dev1 from "../assets/img/techStack/dev1.png";
import dev2 from "../assets/img/techStack/dev2.png";
import dev3 from "../assets/img/techStack/dev3.png";
import data1 from "../assets/img/techStack/data1.png";
import data2 from "../assets/img/techStack/data2.png";
import data3 from "../assets/img/techStack/data3.png";
import data4 from "../assets/img/techStack/data4.png";
import data5 from "../assets/img/techStack/data5.png";
import sale1 from "../assets/img/techStack/sale1.png";
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Card from '@mui/material/Card';

const TechStack = () => {

    const Frontend = [
        {
            url: front1,
            name: 'react',
            width: '20%',
        },
        {
            url: front2,
            name: 'react',
            width: '20%',
        },
        {
            url: front3,
            name: 'react',
            width: '20%',
        },
    ];
    const Backend = [
        {
            url: back1,
            name: 'react',
            width: '20%',
        },
        {
            url: back2,
            name: 'react',
            width: '20%',
        },
        {
            url: back3,
            name: 'react',
            width: '20%',
        },
        {
            url: back4,
            name: 'react',
            width: '20%',
        },
    ];
    const Mobile = [
        {
            url: front1,
            name: 'react',
            width: '20%',
        },
        {
            url: mobile2,
            name: 'react',
            width: '20%',
        },
        {
            url: mobile3,
            name: 'react',
            width: '20%',
        },
        {
            url: mobile4,
            name: 'react',
            width: '20%',
        },
    ];
    const DB = [
        {
            url: db1,
            name: 'react',
            width: '20%',
        },
        {
            url: db2,
            name: 'react',
            width: '20%',
        },
        {
            url: db3,
            name: 'react',
            width: '20%',
        },
    ];
    const cloud = [
        {
            url: cloud1,
            name: 'react',
            width: '20%',
        },
        {
            url: cloud2,
            name: 'react',
            width: '20%',
        },
        {
            url: cloud3,
            name: 'react',
            width: '20%',
        },
        {
            url: cloud4,
            name: 'react',
            width: '20%',
        },
    ];
    const dev = [
        {
            url: dev1,
            name: 'react',
            width: '20%',
        },
        {
            url: dev2,
            name: 'react',
            width: '20%',
        },
        {
            url: dev3,
            name: 'react',
            width: '20%',
        },
    ];
    const data = [
        {
            url: data1,
            name: 'react',
            width: '20%',
        },
        {
            url: data2,
            name: 'react',
            width: '20%',
        },
        {
            url: data3,
            name: 'react',
            width: '20%',
        },
        {
            url: data4,
            name: 'react',
            width: '20%',
        },
        {
            url: data5,
            name: 'react',
            width: '20%',
        },
    ];
    return (
        <div style={{
            fontSize: ' calc(10px + 2vmin)',
            fontFamily: 'Helvetica',
            marginTop: '80px',
            color: Color.black
        }}>
            <Grid container
                direction="column"
                justifyContent="left"
                alignItems="left">
                <Container style={{ textAlign: 'left' }}>
                    <h1 style={{ fontSize: 28, textTransform: 'uppercase', marginTop: 100 }}>SEE OUR</h1>
                    <h1 className="gradient-text" style={{ fontSize: 40, textTransform: 'uppercase' }}>technology stacks</h1>
                    <Typography sx={{ textTransform: 'capitalize', margin: '10px 0' }}>At Lyxux, we leverage the latest and most advanced technologies to deliver exceptional services and solutions. Our robust tech stack ensures efficiency, reliability, and innovation in every project we undertake.</Typography>
                </Container>
            </Grid>
            <Container>
                <h1 style={{ fontSize: 28, textTransform: 'uppercase', marginTop: 100 }}>front end development</h1>
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center">
                    {Frontend.map((vector, index) => (
                        <Grid key={index} item xs={12} sm={4} sx={{ mt: { xs: '30px', md: '0px' } }}>
                            <div style={{ textAlign: 'center', padding: '0 10%' }}>
                                <img src={vector.url} alt="" />
                            </div>
                        </Grid>
                    ))}

                </Grid>
            </Container>
            <Container>
                <h1 style={{ fontSize: 28, textTransform: 'uppercase', marginTop: 100 }}>back end development</h1>
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center">
                    {Backend.map((vector, index) => (
                        <Grid key={index} item xs={12} sm={6} md={3} sx={{ mt: { xs: '30px', md: '0px' } }}>
                            <div style={{ textAlign: 'center', padding: '0 10%' }}>
                                <img src={vector.url} alt="" />
                            </div>
                        </Grid>
                    ))}

                </Grid>
            </Container>
            <Container>
                <h1 style={{ fontSize: 28, textTransform: 'uppercase', marginTop: 100 }}>mobile development</h1>
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center">
                    {Mobile.map((vector, index) => (
                        <Grid key={index} item xs={12} sm={6} md={3} sx={{ mt: { xs: '30px', md: '0px' } }}>
                            <div style={{ textAlign: 'center', padding: '0 10%' }}>
                                <img src={vector.url} alt="" />
                            </div>
                        </Grid>
                    ))}

                </Grid>
            </Container>
            <Container>
                <h1 style={{ fontSize: 28, textTransform: 'uppercase', marginTop: 100 }}>Database Management</h1>
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center">
                    {DB.map((vector, index) => (
                        <Grid key={index} item xs={12} sm={3} sx={{ mt: { xs: '30px', md: '0px' } }}>
                            <div style={{ textAlign: 'center', padding: '0 10%' }}>
                                <img src={vector.url} alt="" />
                            </div>
                        </Grid>
                    ))}

                </Grid>
            </Container>
            <Container>
                <h1 style={{ fontSize: 28, textTransform: 'uppercase', marginTop: 100 }}>cloud platform</h1>
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center">
                    {cloud.map((vector, index) => (
                        <Grid key={index} item xs={12} sm={6} md={3} sx={{ mt: { xs: '30px', md: '0px' } }}>
                            <div style={{ textAlign: 'center', padding: '0 10%' }}>
                                <img src={vector.url} alt="" />
                            </div>
                        </Grid>
                    ))}

                </Grid>
            </Container>
            <Container>
                <h1 style={{ fontSize: 28, textTransform: 'uppercase', marginTop: 100 }}>devops tools</h1>
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center">
                    {dev.map((vector, index) => (
                        <Grid key={index} item xs={12} sm={6} md={3} sx={{ mt: { xs: '30px', md: '0px' } }}>
                            <div style={{ textAlign: 'center', padding: '0 10%' }}>
                                <img src={vector.url} alt="" />
                            </div>
                        </Grid>
                    ))}

                </Grid>
            </Container>
            <Container>
                <h1 style={{ fontSize: 28, textTransform: 'uppercase', marginTop: 100 }}>data analytics and ai</h1>
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center">
                    {data.map((vector, index) => (
                        <Grid key={index} item xs={12} sm={6} md={3} sx={{ mt: { xs: '30px', md: '0px' } }}>
                            <div style={{ textAlign: 'center', padding: '0 10%' }}>
                                <img src={vector.url} alt="" />
                            </div>
                        </Grid>
                    ))}

                </Grid>
            </Container>
            <Container>
                <h1 style={{ fontSize: 28, textTransform: 'uppercase', marginTop: 100 }}>salesforce</h1>
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center">

                    <Grid item xs={12}>
                        <div style={{ textAlign: 'center', padding: '0 10%' }}>
                            <img src={sale1} alt="" />
                        </div>
                    </Grid>


                </Grid>
            </Container>
            <Container sx={{ marginTop: 20,marginBottom:5 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={1}
                >
                    <Grid item

                        justifyContent="center"
                        alignItems="center"
                        sm={6} xs={12} sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
                        <h1 className="gradient-text" style={{ fontSize: 40, textTransform: 'capitalize' }}>Stay Connected</h1>
                        {/* <p>Follow us on social media for the latest updates and industry insights.</p> */}
                        {/* <Grid container
                            direction="row"
                            justifyContent={{ xs: 'center', sm: 'flex-start' }}
                            alignItems={{ xs: 'center', sm: 'flex-start' }} style={{ width: { md: '80%', sm: '100%', xs: '100%' }, marginTop: '20px' }}>

                            <Link href="https://www.linkedin.com/company/lyxux/"
                                underline="none" color="inherit">
                                <LinkedInIcon sx={{ color: Color.gray, fontSize: 70, margin: '0 5px' }} />
                            </Link>
                            <Link href="https://x.com/lyxuxtech"
                                underline="none" color="inherit">
                                <TwitterIcon sx={{ color: Color.gray, fontSize: 70, margin: '0 5px' }} />
                            </Link>
                            <Link href="https://www.facebook.com/share/7kVC56CH7bEpfUrh/?mibextid=LQQJ4d"
                                underline="none" color="inherit">
                                <FacebookIcon sx={{ color: Color.gray, fontSize: 70, margin: '0 5px' }} />
                            </Link>
                            <Link href="https://instagram.com/lyxux_ltd?igsh=MXVwdXljYzBhMng1NQ%3D%3D&utm_source=qr" underline="none"
                                color="inherit">
                                <InstagramIcon sx={{ color: Color.gray, fontSize: 70, margin: '0 5px' }} />
                            </Link>
                        </Grid> */}
                        <h3 style={{ textAlign: 'left', textTransform: 'capitalize' }}>Contact Our Support Team To Grow Your Business</h3>



                        <Carousel cols={1} rows={1} gap={10} loop={true} autoplay={2000}>
                            <Carousel.Item>

                                <Card sx={{
                                    textAlign: {
                                        xs: 'center',
                                        sm: 'left',
                                    }
                                }}>
                                    <Grid container
                                        direction="row" >
                                        <Grid item xs={12} md={6}>
                                            <img src={Spain} style={{ height: '250px' }} alt={'logo'} />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Grid container sx={{
                                                textAlign: {
                                                    xs: 'center',
                                                    sm: 'left',
                                                }
                                            }}
                                                direction="row" style={{ padding: '10px 0' }}>
                                                <Grid item xs={12}>
                                                    <p>Barcelona, Spain</p>
                                                </Grid>
                                                <Grid item xs={12} sm={2}>
                                                    <PhoneIcon sx={{ color: Color.black, fontSize: 30 }} />
                                                </Grid>
                                                <Grid item xs={12} sm={10}
                                                    style={{ color: Color.regular, fontSize: 20 }}>
                                                    <Link href="https://wa.me/+34603845546" underline="none" color="inherit" style={{ color: Color.black, fontSize: 15, fontFamily: 'Helvetica', }}
                                                        onClick={() => {
                                                            window.location.href = `https://wa.me/+34603845546`;
                                                        }}>+34 603 84 55 46</Link>
                                                </Grid>
                                            </Grid>

                                            <Grid container
                                                direction="row" style={{ padding: '10px 0' }}>
                                                <Grid item xs={12} sm={2}>
                                                    <EmailIcon sx={{ color: Color.black, fontSize: 30 }} />
                                                </Grid>
                                                <Grid item xs={12} sm={10}
                                                    style={{
                                                        color: Color.black,
                                                        fontSize: 15,
                                                        fontFamily: 'Helvetica',
                                                    }} onClick={() => {
                                                        window.location.href = `mailto:info@lyxuxglobal.com?`;
                                                    }}><u>info@lyxuxglobal.com</u>
                                                </Grid>
                                            </Grid>
                                            <Grid container
                                                direction="row" style={{ padding: '10px 0' }}>
                                                <Grid item xs={12} sm={2}>
                                                    <LocationOnIcon sx={{ color: Color.black, fontSize: 30 }} />
                                                </Grid>
                                                <Grid item xs={12} sm={10}
                                                    style={{ color: Color.black, fontSize: 15, fontFamily: 'Helvetica', }}>
                                                    <Typography>
                                                        LYXUX GLOBAL,

                                                    </Typography>
                                                    <Typography>
                                                        SOCIEDAD LIMITADA

                                                    </Typography>
                                                    <Typography>
                                                        Barcelona, Spain.
                                                    </Typography>

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>

                            </Carousel.Item>
                            <Carousel.Item>

                                <Card sx={{
                                    textAlign: {
                                        xs: 'center',
                                        sm: 'left',
                                    }
                                }}>
                                    <Grid container
                                        direction="row" >
                                        <Grid item xs={12} md={6}>
                                            <img src={London} style={{ height: '250px' }} alt={'logo'} />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Grid container
                                                direction="row" style={{ padding: '10px 0' }}>
                                                <Grid item xs={12}>
                                                    <p>London, UK</p>
                                                </Grid>
                                                <Grid item xs={12} sm={2}>
                                                    <PhoneIcon sx={{ color: Color.black, fontSize: 30 }} />
                                                </Grid>
                                                <Grid item xs={12} sm={10}
                                                    style={{ color: Color.regular, fontSize: 20 }}>
                                                    <Link href="https://wa.me/+447915611988" underline="none" color="inherit" style={{ color: Color.black, fontSize: 15, fontFamily: 'Helvetica', }}
                                                        onClick={() => {
                                                            window.location.href = `https://wa.me/+447915611988 `;
                                                        }}><Typography>+44 79 156 119 88 </Typography></Link>

                                                </Grid>
                                            </Grid>

                                            <Grid container
                                                direction="row" style={{ padding: '10px 0' }}>
                                                <Grid item xs={12} sm={2}>
                                                    <EmailIcon sx={{ color: Color.black, fontSize: 30 }} />
                                                </Grid>
                                                <Grid item xs={12} sm={10}
                                                    style={{
                                                        color: Color.black,
                                                        fontSize: 15,
                                                        fontFamily: 'Helvetica',
                                                    }} onClick={() => {
                                                        window.location.href = `mailto:info@lyxux.com?`;
                                                    }}><u>info@lyxux.com</u>
                                                </Grid>
                                            </Grid>
                                            <Grid container
                                                direction="row" style={{ padding: '10px 0' }}>
                                                <Grid item xs={12} sm={2}>
                                                    <LocationOnIcon sx={{ color: Color.black, fontSize: 30 }} />
                                                </Grid>
                                                <Grid item xs={12} sm={10}
                                                    style={{ color: Color.black, fontSize: 15, fontFamily: 'Helvetica', }}>

                                                    <Typography>LYXUX LTD
                                                    </Typography>
                                                    <Typography>
                                                        London, UK.</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>

                            </Carousel.Item>

                        </Carousel>



                    </Grid>
                    <Grid item

                        justifyContent="center"
                        alignItems="center"
                        sm={6} xs={12} sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
                        <h1 className="gradient-text" style={{ textAlign: 'right', fontSize: 40, textTransform: 'capitalize' }}>Ask Us Anything!
                        </h1>
                        <p style={{ textAlign: 'right', }}>Fill Out the Form Below and We'll Be in Touch Soon.</p>
                        <ContactForm />

                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default TechStack;
