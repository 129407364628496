export const COLORS = {
    dark: '#0F0D25',
    darkGray:'#1B1930',
    BgGray:'#fbfbfb',
    headingColor:'#3E3E3E',
    purple:'#9430F2',
    lightBlue:'#51B2ED',
    grayBlue:'#7669EF',
    gray:'#413F51',
    white: '#ffffff',
    black: '#1C1C1C',
    buttonBlack:'#292929',
    gradient: ['#9430F2', '#7669EF', '#51B2ED'],
};
