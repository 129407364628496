import * as React from 'react';
import Carousel from 'better-react-carousel';
import { Grid, Container, Box, Typography, Divider, useTheme } from "@mui/material";
import blueIcon from "../assets/img/about us/blueIcon.png";
import ClientCards from "../Common/Component/ClientCards";
import value1 from "../assets/img/about us/value1.png";
import value2 from "../assets/img/about us/value2.png";
import value3 from "../assets/img/about us/value3.png";
import value4 from "../assets/img/about us/value4.png";
import Vector1 from "../assets/img/about us/Vector1.png";
import Vector2 from "../assets/img/about us/Vector2.png";
import Vector3 from "../assets/img/about us/Vector3.png";
import Vector4 from "../assets/img/about us/Vector4.png";
import Vector5 from "../assets/img/about us/Vector5.png";
import Vector6 from "../assets/img/about us/Vector6.png";
import Vector7 from "../assets/img/about us/Vector7.png";
import Vector8 from "../assets/img/about us/Vector8.png";
import Vector9 from "../assets/img/about us/Vector9.png";
import Vector10 from "../assets/img/about us/Vector10.png";
import Vector11 from "../assets/img/about us/Vector11.png";
import Vector12 from "../assets/img/about us/Vector12.png";
import HoverVector1 from "../assets/img/about us/HoverVector1.png";
import HoverVector2 from "../assets/img/about us/HoverVector2.png";
import HoverVector3 from "../assets/img/about us/HoverVector3.png";
import HoverVector4 from "../assets/img/about us/HoverVector4.png";
import HoverVector5 from "../assets/img/about us/HoverVector5.png";
import HoverVector6 from "../assets/img/about us/HoverVector6.png";
import HoverVector7 from "../assets/img/about us/HoverVector7.png";
import HoverVector8 from "../assets/img/about us/HoverVector8.png";
import HoverVector9 from "../assets/img/about us/HoverVector9.png";
import HoverVector10 from "../assets/img/about us/HoverVector10.png";
import HoverVector11 from "../assets/img/about us/HoverVector11.png";
import HoverVector12 from "../assets/img/about us/HoverVector12.png";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { COLORS as Color } from "../Common/Constant/Color";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useNavigate } from "react-router-dom";
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';

const VisionMissonCard = styled(Card)(({ theme }) => ({
    textAlign: 'left',
    boxShadow: 'none',
    '&:hover': {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        borderRadius: 10
    },
}));

const GradientTextSpan = styled('span')({
    background: '-webkit-linear-gradient(90deg, #7669EF 0%, #7669EF 25%, #51B2ED 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
});
const About = () => {
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = React.useState(false);
    const [hoverIndex, setHoverIndex] = React.useState(0);
    const timelineData = [
        { title: 'Discovery', description: 'Our team analyzes your requirements and expectations, considers your specific needs, and formulates an initial plan for your review.', side: 'left' },
        { title: 'Design', description: 'From a streamlined architecture to an intuitive user interface, we develop, assess, and refine every design aspect before seeking your approval.', side: 'right' },
        { title: 'Specification', description: 'We conduct thorough due diligence, confirm features with you, agree on timelines, perform feasibility analysis, and finalize the design blueprint.', side: 'left' },
        { title: 'Development', description: 'Our developers, graphic designers, and project managers collaborate to create efficient, high-quality software tailored to your specifications.', side: 'right' },
        { title: 'Quality Assurance', description: 'Embracing test-driven development, we perform rigorous functional and security testing to ensure the software meets the highest standards.', side: 'left' },
        { title: 'Deployment', description: 'Upon receiving your approval, we deploy the software to your desired environment and transfer all custom solution rights to you.', side: 'right' },
        { title: 'Support & Maintenance', description: 'While we design our software for easy maintenance by your team, we also offer ongoing support and maintenance services for more complex or large-scale projects.', side: 'left' },
    ];
    const Vectors = [
        {
            url: Vector1,
            hoverUrl: HoverVector1,
            title: 'finance',
            width: '20%',
        },
        {
            url: Vector2,
            hoverUrl: HoverVector2,
            title: 'food',
            width: '20%',
        },
        {
            url: Vector3,
            hoverUrl: HoverVector3,
            title: 'hotel',
            width: '20%',
        },
        {
            url: Vector4,
            hoverUrl: HoverVector4,
            title: 'CRM',
            width: '20%',
        },
        {
            url: Vector5,
            hoverUrl: HoverVector5,
            title: 'real state',
            width: '20%',
        },
        {
            url: Vector6,
            hoverUrl: HoverVector6,
            title: 'Entertainment',
            width: '20%',
        },
        {
            url: Vector7,
            hoverUrl: HoverVector7,
            title: 'sports',
            width: '20%',
        },
        {
            url: Vector8,
            hoverUrl: HoverVector8,
            title: 'education',
            width: '20%',
        },
        {
            url: Vector9,
            hoverUrl: HoverVector9,
            title: 'visual graphics',
            width: '20%',
        },
        {
            url: Vector10,
            hoverUrl: HoverVector10,
            title: 'marketing',
            width: '20%',
        },
        {
            url: Vector11,
            hoverUrl: HoverVector11,
            title: 'healthcare',
            width: '20%',
        },
        {
            url: Vector12,
            hoverUrl: HoverVector12,
            title: 'ecommerce',
            width: '20%',
        },
    ];
    const HowWeWork = [
        {
            title: 'Dedicated teams',
            description: 'We will assemble for you a dedicated team of experts, who will focus exclusively on your project and on building an app that fully reflects your needs.',
        },
        {
            title: 'Client is in control',
            description: 'For the development time, you will become a part of our development team and will be able to guide our developers’ work in real time.',
        },
        {
            title: 'All our talents at your service',
            description: 'The development team will provide you full-cycle development services or get started at any project stage with as many experts as you need.',
        },
        {
            title: 'Established procedure',
            description: 'Our elaborated development procedure implies clear division of responsibilities, flawless development process, effective quality management and communication systems.',
        },
        {
            title: 'TDD',
            description: 'We use the best automated testing methods to guarantee that your application behaves as intended and ensure that making changes doesn’t cause any unexpected side effects.',
        },
        {
            title: 'Knowledge sharing',
            description: 'Each our developer is backed up by the entire Anadea team with all the huge experience, accumulated during our many years in the IT business.',
        },
    ];
    const HowItGoes = [
        {
            title: 'Discovery',
            description: 'Our team analyzes your requirements and expectations, considers your specific needs, and formulates an initial plan for your review.',
        },
        {
            title: 'Design',
            description: 'From a streamlined architecture to an intuitive user interface, we develop, assess, and refine every design aspect before seeking your approval.',
        },
        {
            title: 'Specification',
            description: 'We conduct thorough due diligence, confirm features with you, agree on timelines, perform feasibility analysis, and finalize the design blueprint.',
        },
        {
            title: 'Development',
            description: 'Our developers, graphic designers, and project managers collaborate to create efficient, high-quality software tailored to your specifications.',
        },
        {
            title: 'Quality Assurance',
            description: 'Embracing test-driven development, we perform rigorous functional and security testing to ensure the software meets the highest standards.',
        },
        {
            title: 'Deployment',
            description: 'Upon receiving your approval, we deploy the software to your desired environment and transfer all custom solution rights to you.',
        },
        {
            title: 'Support & Maintenance',
            description: 'While we design our software for easy maintenance by your team, we also offer ongoing support and maintenance services for more complex or large-scale projects.',
        },

    ];
    const theme = useTheme();


    return (
        <div style={{
            fontSize: ' calc(10px + 2vmin)',
            fontFamily: 'Helvetica',
            marginTop: '80px',
            color: Color.black
        }}>


            {/* Who We Are */}
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center" spacing={1} style={{ marginTop: "20px" }}>

                <Grid item xs={12}>
                    <div style={{ textAlign: 'center', padding: '0 4%' }}>

                        <Typography className="gradient-text" sx={{ fontSize: { xs: 30, md: 40 }, fontWeight: 700 }}>Who We Are</Typography>
                        <Typography sx={{ color: Color.headingColor, fontSize: { xs: '15px', md: 23 }, textTransform: 'capitalize', }}>LYXUX is a leading provider of innovative software solutions, combining <GradientTextSpan>expertise, creativity,</GradientTextSpan> and a <GradientTextSpan>client-centric approach</GradientTextSpan> to drive business growth and <GradientTextSpan>create exceptional user experiences.</GradientTextSpan> </Typography>
                        <img style={{ width: "80px" }} src={blueIcon} alt="" />

                    </div>
                </Grid>
            </Grid>

            {/* How We Work */}
            <Container sx={{ marginTop: 10 }}>
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center" spacing={2} style={{ marginTop: "20px" }}>

                    <Grid item xs={12}>
                        <div style={{ textAlign: 'center', padding: '0 4%' }}>
                            <Typography className="gradient-text" sx={{ fontSize: { xs: 30, md: 40 }, fontWeight: 700 }}>How we work</Typography>
                            <Typography sx={{ color: Color.headingColor, fontSize: { xs: '15px', md: 23 }, textTransform: 'capitalize', }}>We follow the agile development methodology and we are open to changes and new ideas from your side at any stage of the development. </Typography>
                        </div>
                    </Grid>
                    {HowWeWork.map((work, index) => (
                        <Grid key={index} item xs={12} sm={6} md={4} sx={{ textAlign: 'left' }}>
                            <Typography sx={{
                                fontSize: '22px',
                                lineHeight: '30px',
                                fontWeight: 800
                            }}>{work.title}</Typography>
                            <Typography sx={{
                                fontSize: '18px',
                                lineHeight: '28px',
                                fontWeight: 400
                            }}>{work.description}</Typography>
                        </Grid>
                    ))}
                </Grid>
            </Container>

            {/* How it goes */}
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn">
            <Container sx={{ marginTop: 15 }}>
                <Typography className="gradient-text" sx={{ fontSize: { xs: 30, md: 40 }, fontWeight: 700 }}>How it goes, step by step</Typography>
                <Typography sx={{ color: Color.headingColor, fontSize: { xs: '15px', md: 23 }, textTransform: 'capitalize', }}>We use a traditional software development lifecycle in order to keep the process open, transparent, and give you a clear road map to the successful conclusion of your project.</Typography>
                <Timeline position="alternate">
                    {HowItGoes.map((item, index) => (
                        <TimelineItem key={index} >
                            <TimelineSeparator>
                                <TimelineDot variant="outlined" />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{
                                display: 'flex',
                                flexDirection: index % 2 === 0 ? 'row' : 'row-reverse',
                            }}>
                                <Box sx={{
                                    padding: 3,
                                    maxWidth: '350px',
                                    backgroundColor: '#0F0D25',
                                    color: 'white',
                                    borderRadius: '8px',
                                    '&::before': {
                                        backgroundColor: '#28a745',
                                        borderRadius: '50%',
                                    },
                                }}>
                                    <Typography variant="h6" gutterBottom>{item.title}</Typography>
                                    <Typography variant="body2">{item.description}</Typography>
                                </Box>
                            </TimelineContent>
                        </TimelineItem>
                    ))}

                </Timeline>
            </Container>
            </AnimationOnScroll>

            {/* Our Values */}
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeDown">
            <Container>
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center" spacing={1} style={{ marginTop: "10px" }}>

                    <Grid item xs={12}>
                        <div style={{ textAlign: 'center', padding: '0 10%' }}>

                            <h1 style={{ fontSize: 40, }}>Our Values.</h1>


                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ textAlign: 'center', padding: '0 10%' }}>

                            <Typography sx={{ textTransform: 'capitalize', fontSize: 18 }}>These four principles highlight our fundamental beliefs as a team and as a business. Our core values attract and unite individuals focused on building a stable, desirable work environment and providing exceptional solutions we are proud of.</Typography>


                        </div>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <div style={{ textAlign: 'center', padding: '0 10%' }}>

                            <img style={{ width: "65%" }} src={value1} alt="" />
                            <h3>Quality</h3>

                        </div>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <div style={{ textAlign: 'center', padding: '0 10%' }}>

                            <img style={{ width: "65%" }} src={value2} alt="" />
                            <h3>Customer Focus</h3>

                        </div>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <div style={{ textAlign: 'center', padding: '0 10%' }}>

                            <img style={{ width: "65%" }} src={value3} alt="" />
                            <h3>Innovation</h3>
                        </div>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <div style={{ textAlign: 'center', padding: '0 10%' }}>

                            <img style={{ width: "65%" }} src={value4} alt="" />
                            <h3>Intergrity</h3>
                        </div>
                    </Grid>

                </Grid>
            </Container>
            </AnimationOnScroll>

            {/*start vision & mission*/}
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn"
                style={{ marginTop: '100px', }}>
                <Container>
                    <Grid container
                        direction="row"
                        justifyContent="left"
                        alignItems="stretch"
                        spacing={10}

                        style={{
                            background: Color.white,

                        }}>
                        <Grid item xs={12} sm={6} justifyContent="center"
                            alignItems="left">
                            <VisionMissonCard>
                                <Container>
                                    <h1>Our Vision</h1>

                                    <p style={{ fontSize: 18, lineHeight: 1.5, textTransform: 'capitalize' }}>At LYXUX, our vision is to be the leading provider of software worldwide, setting the standard for <b>innovation</b>, <b>quality</b>, and <b>customer satisfaction</b>. We aim to empower our customers by delivering solutions that enhance their lives and drive their success.</p>
                                </Container>
                            </VisionMissonCard>
                        </Grid>
                        <Grid item xs={12} sm={6} justifyContent="center"
                            alignItems="center" >
                            <VisionMissonCard style={{ height: '100%' }}>
                                <Container>
                                    <h1>Our Mission</h1>

                                    <p style={{ fontSize: 18, lineHeight: 1.5, textTransform: 'capitalize' }}>Our mission is to revolutionize the software industry by <b>offering cutting-edge software services</b> that exceed expectations.</p>
                                </Container>
                            </VisionMissonCard>
                        </Grid>
                    </Grid>
                </Container>
            </AnimationOnScroll>
            

            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn"
                style={{ marginTop: '100px', }}>
                <Container>
                    <h1 style={{ color: Color.black, fontSize: 40, textTransform: 'capitalize' }}>our industry focus</h1>
                    <Grid container
                        direction="row"
                        justifyContent="center"
                        alignItems="center" spacing={0} style={{ marginTop: "20px", padding: '0 10%' }}>
                        {Vectors.map((vector, index) => (
                            <Grid key={index} item xs={6} sm={4} md={3}>
                                <div key={index} onMouseEnter={() => {
                                    setHoverIndex(index);
                                    setIsHovered(true)
                                }}
                                    onMouseLeave={() => {
                                        setHoverIndex(0);
                                        setIsHovered(false);
                                    }} style={{ textAlign: 'center', padding: '0 10%' }}>
                                    <img style={{ width: '100%', display: isHovered && hoverIndex === index ? 'none' : 'unset' }} src={vector.url} alt="" />
                                    <img style={{ width: '100%', display: isHovered && hoverIndex === index ? 'unset' : 'none' }} src={vector.hoverUrl} alt="" />
                                </div>
                            </Grid>
                        ))}

                    </Grid>
                </Container>
            </AnimationOnScroll>

{/* 
            <Grid>
                <Grid style={{ paddingBottom: '20px', marginTop: '100px' }}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={5}
                    >
                        <Grid item>
                            <AnimationOnScroll animateIn="animate__fadeInRight">
                            <h1 style={{ color: Color.black, fontSize: 40, }}>WHO WE WORK WITH</h1>
                            </AnimationOnScroll>


                        </Grid>
                    </Grid>
                    <AnimationOnScroll animateIn="animate__fadeIn" style={{width: '100%'}}>
                    <ClientCards />
                    </AnimationOnScroll>
                </Grid>
            </Grid> */}
        </div>
    )
}
export default About;
