import * as React from 'react';
import Carousel from 'better-react-carousel';
import './css/home.css'
import main from "../assets/img/home/main.avif";
import mainBG from "../assets/img/home/mainBG.png";
import service1 from "../assets/img/home/service1.png";
import service2 from "../assets/img/home/service2.png";
import service3 from "../assets/img/home/service3.png";
import service4 from "../assets/img/home/service4.png";
import service5 from "../assets/img/home/service5.png";
import service6 from "../assets/img/home/service6.png";
import service7 from "../assets/img/home/service7.png";
import service8 from "../assets/img/home/service8.png";
import service9 from "../assets/img/home/service9.png";
import service10 from "../assets/img/home/service10.png";
import front1 from "../assets/img/techStack/front1.png";
import front2 from "../assets/img/techStack/front2.png";
import front3 from "../assets/img/techStack/front3.png";
import back1 from "../assets/img/techStack/back1.png";
import back2 from "../assets/img/techStack/back2.png";
import back3 from "../assets/img/techStack/back3.png";
import back4 from "../assets/img/techStack/back4.png";
import mobile2 from "../assets/img/techStack/mobile2.png";
import mobile3 from "../assets/img/techStack/mobile3.png";
import mobile4 from "../assets/img/techStack/mobile4.png";
import db1 from "../assets/img/techStack/db1.png";
import db2 from "../assets/img/techStack/db2.png";
import db3 from "../assets/img/techStack/db3.png";
import cloud1 from "../assets/img/techStack/cloud1.png";
import cloud2 from "../assets/img/techStack/cloud2.png";
import cloud3 from "../assets/img/techStack/cloud3.png";
import cloud4 from "../assets/img/techStack/cloud4.png";
import dev1 from "../assets/img/techStack/dev1.png";
import dev2 from "../assets/img/techStack/dev2.png";
import dev3 from "../assets/img/techStack/dev3.png";
import data1 from "../assets/img/techStack/data1.png";
import data2 from "../assets/img/techStack/data2.png";
import data3 from "../assets/img/techStack/data3.png";
import data4 from "../assets/img/techStack/data4.png";
import data5 from "../assets/img/techStack/data5.png";
import sale1 from "../assets/img/techStack/sale1.png";
import { Button, Grid, Container, Typography, Box, Card } from "@mui/material";
import { AppBar, Toolbar, IconButton } from '@mui/material';
import { ShoppingCart, ArrowForward } from '@mui/icons-material';
import { COLORS as Color } from "../Common/Constant/Color";
import { useNavigate } from "react-router-dom";
import OutlinedInput from '@mui/material/OutlinedInput';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'react-phone-input-2/lib/style.css';
import './css/career.css';
import ContactForm from './../Common/Component/ContactForm';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const OfferCard = styled(Card)(({ theme }) => ({
    padding: '20px 10px',
    width: '100%',
    height: '100%',
    boxShadow: 'none',
    '&:hover': {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        borderRadius: 10
    },
}));
const Home = () => {
    const techStack = [
        {
            url: front1,
            name: 'react',
            width: '20%',
        },
        {
            url: front2,
            name: 'react',
            width: '20%',
        },
        {
            url: front3,
            name: 'react',
            width: '20%',
        },
    
        {
            url: back1,
            name: 'react',
            width: '20%',
        },
        {
            url: back2,
            name: 'react',
            width: '20%',
        },
        {
            url: back3,
            name: 'react',
            width: '20%',
        },
        {
            url: back4,
            name: 'react',
            width: '20%',
        },
    
        {
            url: front1,
            name: 'react',
            width: '20%',
        },
        {
            url: mobile2,
            name: 'react',
            width: '20%',
        },
        {
            url: mobile3,
            name: 'react',
            width: '20%',
        },
        {
            url: mobile4,
            name: 'react',
            width: '20%',
        },
    
        {
            url: db1,
            name: 'react',
            width: '20%',
        },
        {
            url: db2,
            name: 'react',
            width: '20%',
        },
        {
            url: db3,
            name: 'react',
            width: '20%',
        },
    
        {
            url: cloud1,
            name: 'react',
            width: '20%',
        },
        {
            url: cloud2,
            name: 'react',
            width: '20%',
        },
        {
            url: cloud3,
            name: 'react',
            width: '20%',
        },
        {
            url: cloud4,
            name: 'react',
            width: '20%',
        },
    
        {
            url: dev1,
            name: 'react',
            width: '20%',
        },
        {
            url: dev2,
            name: 'react',
            width: '20%',
        },
        {
            url: dev3,
            name: 'react',
            width: '20%',
        },
   
        {
            url: data1,
            name: 'react',
            width: '20%',
        },
        {
            url: data2,
            name: 'react',
            width: '20%',
        },
        {
            url: data3,
            name: 'react',
            width: '20%',
        },
        {
            url: data4,
            name: 'react',
            width: '20%',
        },
        {
            url: data5,
            name: 'react',
            width: '20%',
        },
    ];
    const services = [
        {
            url: service1,
            title: 'IOS Development',
            description: 'LYXUX offers advanced iOS app development, utilizing the latest technologies and industry best practices to create captivating mobile experiences for Apple devices, delivering high-quality, engaging apps that drive success.',
        },
        {
            url: service2,
            title: 'web development',
            description: 'Our web development solutions focus on creating visually stunning, user-friendly, and functional websites, ensuring an exceptional online experience for visitors through responsive design and seamless navigation.',
        },
        {
            url: service3,
            title: 'mobile development',
            description: 'LYXUX offers advanced iOS app development, utilizing the latest technologies and industry best practices to create captivating mobile experiences for Apple devices, delivering high-quality, engaging apps that drive success.',
        },

        {
            url: service4,
            title: 'digital marketing',
            description: 'LYXUX offers comprehensive digital marketing services, optimizing websites for search engines, enhancing social media engagement, and employing data-driven strategies for maximum ROI and continuous growth.',
        },
        {
            url: service5,
            title: 'enterprise solutions',
            description: 'LYXUX Enterprise Solutions streamline business operations, enhance collaboration, and drive growth by integrating seamlessly into existing systems, empowering organizations to reach their full potential.',
        },
        {
            url: service6,
            title: 'blockchain development',
            description: 'LYXUX offers comprehensive services for blockchain technology, including development, smart contract implementation, and DApp creation, transforming business operations, enhancing data integrity, and facilitating secure transactions.',
        },
        {
            url: service7,
            title: 'multimedia creations',
            description: 'LYXUX offers comprehensive digital marketing services, optimizing websites for search engines, enhancing social media engagement, and employing data-driven strategies for maximum ROI and continuous growth.',
        },
        {
            url: service8,
            title: 'iot solutions',
            description: 'LYXUX offers comprehensive services in IoT, focusing on device communication, architecture design, and data analytics to drive innovation, optimize operations, and embrace the digital revolution.',
        },
        {
            url: service9,
            title: 'salesforce',
            description: 'LYXUX offers comprehensive digital marketing services, optimizing websites for search engines, enhancing social media engagement, and employing data-driven strategies for maximum ROI and continuous growth.',
        },
        {
            url: service10,
            title: 'UI/UX designing',
            description: 'LYXUX offers comprehensive services in IoT, focusing on device communication, architecture design, and data analytics to drive innovation, optimize operations, and embrace the digital revolution.',
        },

    ];

    const products = [
        { name: 'IMPULZE TRADE', nav: '/' },
        { name: 'BEE IN MIND', nav: '/' },
        { name: 'LYXUX MAGAZINE', nav: '/' },
        { name: 'LYX SPORT', nav: '/' },
        { name: 'LYX MEDIA', nav: '/' },]
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = React.useState(false);
    const [hoverIndex, setHoverIndex] = React.useState(0);

    const [formData, setFormData] = React.useState({
        name: '',
        email: '',
        contactNo: '',
        subject: '',
        requirement: ''
    })

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const handleMailSend = () => {
        const { name, email, contactNo, requirement } = formData

        const mailtoLink = `mailto:info@lyxux.com?subject=Free%20Quote%20Request&body=Name:%20${encodeURIComponent(
            name
        )}%0AEmail:%20${encodeURIComponent(email)}%0AContact%20Number:%20${encodeURIComponent(
            contactNo
        )}%0ARequirement:%20${encodeURIComponent(requirement)}`

        window.location.href = mailtoLink;
    }

    const MainButton = styled(Button)(({ theme }) => ({
        backgroundColor: Color.white,
        color: Color.dark,
        fontSize: 23,
        fontWeight: 'bold',
        textTransform: 'capitalize',
        border: `1px solid ${Color.dark}`,
        '&:hover': {
            backgroundColor: Color.dark,
            color: Color.white,
        },
    }));
    const SendButton = styled(Button)(({ theme }) => ({
        color: Color.dark,
        backgroundColor: Color.white,
        fontSize: 23,
        fontWeight: 'bold',
        textTransform: 'capitalize',
        border: `1px solid ${Color.dark}`,
        width: '100%',
        '&:hover': {
            backgroundColor: Color.dark,
            color: Color.white,
        },
    }));
    const ViewMoreButton = styled(Button)(({ theme }) => ({
        color: Color.dark,
        backgroundColor: Color.white,
        fontSize: 17,
        fontWeight: 'bold',
        textTransform: 'capitalize',
        border: `1px solid ${Color.dark}`,
        marginTop: 30,
        padding: '0 10px',
        '&:hover': {
            backgroundColor: Color.dark,
            color: Color.white,
        },
    }));
    const InvisibleViewMoreButton = styled(Button)(({ theme }) => ({
        color: 'transparent',
        backgroundColor: 'transparent',
        fontSize: 17,
        fontWeight: 'bold',
        textTransform: 'capitalize',
        border: `1px solid transparent`,
        marginTop: 30,
        padding: '0 10px'
    }));
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div style={{
            fontSize: ' calc(10px + 2vmin)',
            fontFamily: 'Helvetica',
            marginTop: '80px'
        }}>
            {/* <Box style={{ backgroundImage: `linear-gradient(to right, #9430ff1a, #7669ef1a, #51b2ed1a)`, justifyContent: "space-between" }}>

                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                    {products.map((item, index) => (
                        <Button key={index} className="gradient-text" style={{ fontFamily: 'Be Vietnam Pro', fontWeight: 400 }}
                            onClick={() => {
                                window.scrollTo(0, 0);
                                navigate(item.nav)
                            }} children={item.name} />
                    ))}
                </Box>
            </Box> */}
            <div>
                <Box sx={{
                    margin: 0, textAlign: 'center', backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),url(${mainBG})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center', maxWidth: '100%',
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Typography variant="h2" component="h1" fontWeight="bold" sx={{ color: 'white', paddingTop: 10 }}>
                        Unleash the Power of
                    </Typography>
                    <Typography variant="h2" component="h1" fontWeight="bold" sx={{ color: 'white' }}>
                        Innovation Software Solutions
                    </Typography>
                    <Typography variant="h6" component="p" sx={{ mt: 2, ml: 4, mr: 4, color: 'white' }}>
                        Our mission is to deliver cutting-edge software solutions that drive business growth, enhance productivity, and create exceptional user experiences.
                    </Typography>
                    <Box sx={{ mt: 4, pb: 10 }}>
                        <Button onClick={() => {
                            window.scrollTo(0, 0);
                            navigate('/contact')
                        }} variant="contained" sx={{ mx: 2, backgroundColor: 'black', color: 'white', fontWeight: 'bold' }}>
                            Start a Project
                        </Button>
                        <Button onClick={() => {
                            window.scrollTo(0, 0);
                            navigate('/ourClients')
                        }} variant="contained" sx={{ mx: 2, backgroundColor: 'white', color: 'black', fontWeight: 'bold' }}>
                            Our Clients
                        </Button>
                    </Box>
                </Box>
            </div>


            <div class="slider">
                <div class="slide-track">
                    {techStack.map((tech, index) => (
                        <div class="slide" key={index}>
                            <img style={{width:50}}
                                src={tech.url} alt=""
                            />
                        </div>
                    ))}
{techStack.map((tech, index) => (
                        <div class="slide" key={index}>
                            <img style={{width:50}}
                                src={tech.url} alt=""
                            />
                        </div>
                    ))}
                </div>
            </div>
            {/* <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center" spacing={1}>

                <Grid item xs={12} md={6} sx={{ textAlign: { md: 'left', sm: 'center' } }}>
                    <Grid sx={{ paddingLeft: { sm: 5, xs: 0 } }}>
                        <Typography sx={{ color: Color.headingColor, fontSize: { xs: '20px', md: '40px', fontWeight: 700 } }}>Unleash the Power of</Typography>

                        <Typography className="gradient-text" sx={{
                            fontSize: { xs: 30, md: 60 }, fontWeight: 700
                        }}>Innovation Software Solutions</Typography>

                        <Typography sx={{ color: Color.headingColor, fontSize: { xs: 15, md: 25 } }}>
                            Our mission is to deliver cutting-edge software solutions that drive business growth, enhance productivity, and create exceptional user experiences.
                        </Typography>

                        <MainButton onClick={handleClickOpen} >Free Consultation</MainButton>

                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} sx={{ textAlign: 'right', display: { md: 'block', sm: 'none', xs: 'none' } }}>
                    <img style={{ width: "85%" }} src={main} alt="" />
                </Grid>
            </Grid> */}

            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center" spacing={1} style={{ marginTop: "20px" }}>

                    <Grid item xs={12}>
                        <div style={{ textAlign: 'center', padding: '0 10%' }}>

                            <Typography sx={{ color: Color.black, fontSize: { xs: 30, md: 40 }, fontWeight: 700 }}>WELCOME TO LYXUX LTD</Typography>
                            <Typography sx={{ color: Color.headingColor, fontSize: { xs: 18, md: 23 }, textTransform: 'capitalize', }}>We strive to be the preferred technology partner, providing <b>customized solutions</b> and unmatched support to help businesses thrive in the digital era. Together, we <b>innovate, transform,</b> and <b>achieve</b> remarkable success.</Typography>
                            <Button variant="contained" style={AboutButtonStyle} onClick={() => {
                                window.scrollTo(0, 0);
                                navigate('/about')
                            }}
                            >About Us</Button>

                        </div>
                    </Grid>
                </Grid>
            </AnimationOnScroll>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
                <Container>
                    <Grid style={{ paddingBottom: '20px', marginTop: '100px', }}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={5}
                            style={{ overflow: "hidden" ,paddingBottom:20}}
                        >
                            <Grid item>
                                {/* <AnimationOnScroll animateIn="animate__fadeInRight"> */}
                                <Typography sx={{ color: Color.black, fontSize: { xs: 30, md: 40 }, fontWeight: 700, textTransform: 'uppercase', }}>what we offer</Typography>
                                {/* </AnimationOnScroll> */}


                            </Grid>
                            {/* <AnimationOnScroll animateIn="animate__fadeIn" style={{ width: '100%' }}> */}
                            <Grid sx={{
                                justifyContent: 'center',
                                flexWrap: 'wrap',
                                paddingLeft: 6
                            }} container spacing={5}>
                                {services.map((service, index) => (
                                    <Grid key={index} item xs={12} sm={6} md={3}>
                                        <OfferCard onMouseEnter={() => {
                                            setHoverIndex(index);
                                            setIsHovered(true)
                                        }}
                                            onMouseLeave={() => {
                                                setHoverIndex(0);
                                                setIsHovered(false);
                                            }}>
                                            <Container>
                                                <img style={{ height: '70px' }} src={service.url} alt="" />
                                                <Typography sx={{ fontSize: 20, fontWeight: 600, textTransform: 'uppercase' }} gutterBottom variant="h5" component="div">
                                                    {service.title}
                                                </Typography>
                                                <ViewMoreButton onClick={() => {
                                                    window.scrollTo(0, 0);
                                                    navigate('/services')
                                                }} style={{ display: isHovered && hoverIndex === index ? 'unset' : 'none' }}>View More</ViewMoreButton>
                                                <InvisibleViewMoreButton style={{ display: isHovered && hoverIndex === index ? 'none' : 'unset' }}>View More</InvisibleViewMoreButton>
                                            </Container>
                                        </OfferCard>
                                    </Grid>
                                ))}
                            </Grid>
                            {/* </AnimationOnScroll> */}
                        </Grid>
                    </Grid>
                </Container>
            </AnimationOnScroll>


            {/* <Typography sx={{ color: Color.black, fontSize: { xs: 30, md: 40 }, fontWeight: 700, textTransform: 'uppercase', marginTop: '130px' }}>Get in Touch with us</Typography>
            <h1 className="gradient-text" style={{
                fontSize: 40, margin: 0, fontWeight: 'normal', marginTop: 80
            }}>Ask Us Anything! </h1>
            <p>Fill Out the Form Below and We'll Be in Touch Soon.</p>
            <ContactForm /> */}
            <Grid sx={{ backgroundColor: '#1a202a', color: 'white', padding: 5, textAlign: 'left' }} container spacing={3}>
                <Grid item xs={12} md={6}>
                <AnimationOnScroll style={{overflow: 'hidden'}} animateIn="animate__fadeInLeft">
                    <h1>Elevating Your Business With IT Solutions</h1>
                    <p style={{ fontSize: 15, lineHeight: 2 }}>We Strive To Be The Preferred Technology Partner, Providing Customized Solutions And Unmatched Support To Help Businesses Thrive In The Digital Era. Together, We Innovate, Transform, And Achieve Remarkable Success.</p>
                    <Button onClick={() => {
                        window.scrollTo(0, 0);
                        navigate('/contact')
                    }} sx={{ color: 'white', borderColor: 'white' }} variant="outlined">Contact Us</Button>
                    </AnimationOnScroll>
                </Grid>
                <Grid style={{overflow: 'hidden'}} item xs={12} md={6}>
                      <AnimationOnScroll style={{overflow: 'hidden'}} animateIn="animate__fadeInRight"> 
                    <img style={{ width: "80%" }} src={main} alt="" />
                    </AnimationOnScroll>
                </Grid>
            </Grid>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >

                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={5}
                    >
                        <Grid item

                            justifyContent="center"
                            alignItems="center"
                            xs={10} style={{ textAlign: 'left' }}>
                            <h1>Get Your Free Quote Today</h1>
                            <p>We Are Exited To Provide You With A Customized Quote Tailored To Your Needs. Please Fill Out The Form Below, And Our Team Will Get Back To You Shortly With All The Details.</p>
                            <label style={{ textAlign: 'left', fontSize: 18, color: Color.gray }}>Your Name</label>
                            <OutlinedInput size="small" style={textfieldStyle}
                                name='name'
                                value={formData.name}
                                onChange={handleChange}
                            />
                            <label style={{ textAlign: 'left', fontSize: 18, color: Color.gray }}>Email</label>
                            <OutlinedInput size="small" style={textfieldStyle}
                                name='email'
                                value={formData.email}
                                onChange={handleChange}
                            />
                            <label style={{ textAlign: 'left', fontSize: 18, color: Color.gray }}>Contact Number</label>
                            <OutlinedInput size="small" style={textfieldStyle}
                                name='contactNo'
                                value={formData.contactNo}
                                onChange={handleChange}
                            />
                            <label style={{ textAlign: 'left', fontSize: 18, color: Color.gray }}>Your Requirement</label>
                            <OutlinedInput style={textfieldStyle} multiline
                                rows={4}
                                name='requirement'
                                value={formData.requirement}
                                onChange={handleChange}
                            />
                            <SendButton onClick={handleMailSend}
                            >Send</SendButton>
                        </Grid>
                    </Grid>
                </DialogContent>

            </BootstrapDialog>
        </div >
    )
}

const AboutButtonStyle = {
    marginTop:20,
    backgroundColor: Color.dark,
    padding: '4px 28px',
    color: Color.white,
    fontSize: 23,
    fontWeight: 'bold',
    textTransform: 'capitalize',
};
const textfieldStyle = {
    border: 'double 1px transparent',
    backgroundImage: `linear-gradient(white, white),  linear-gradient(to right, ${Color.purple},${Color.grayBlue},${Color.lightBlue})`,
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',
    width: '100%',
    borderRadius: 10,
    margin: '5px 0 10px 0',
};
export default Home;
