import * as React from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import LOGO from "../../assets/img/lyxuxLogo.png";
import LyxButtonLOGO from "../../assets/img/headerButtonLogo.png";
import { Button } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import { COLORS as Color } from "../Constant/Color";
import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './Header.scss';

const navItems = [
    { name: 'HOME', nav: '/' },
    { name: 'COMPANY', nav: '/about' },
    { name: 'SERVICES', nav: '/services' },
    // { name: 'CAREERS', nav: '/careers' },
    // { name: 'EVENTS', nav: '/events' },
    // { name: 'BOOKING', nav: '/booking' },
    { name: 'CONTACT US', nav: '/contact' },]

const companyItems = [
    { name: 'ABOUT US', nav: '/about' },
    // { name: 'OUR FAMILY', nav: '/ourTeam' },
    // { name: 'OUR CLIENTS', nav: '/ourClients' },
    // { name: 'TECH STACK', nav: '/techStack' },
    // { name: 'GALLERY', nav: '/gallery' },
]


const ColorButton = styled(Button)(({ theme }) => ({
    fontFamily: 'Inter',
    fontSize: 18,
    fontWeight: 700,
    borderRadius: 5,
    padding: '2px 18px',
    backgroundImage: `linear-gradient(to right, ${Color.purple},${Color.grayBlue},${Color.lightBlue})`,
    // backgroundColor: purple[500],
    // '&:hover': {
    //     backgroundColor: purple[700],
    // },
}));
const StyledListItemText = styled(ListItemText)(({ theme }) => ({
    color: Color.dark,
    fontFamily: 'Be Vietnam Pro',
    fontWeight: 400
}));

const Header = () => {
    const navigate = useNavigate();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [isClick, setIsclick] = React.useState(false)
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleClickCompany = () => {
        setIsclick(!isClick)
    }
    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>

            <Typography variant="h6" sx={{ my: 2 }}>
                <img src={LOGO} style={{ width: '80px' }} alt={'logo'} />
            </Typography>
            <List>
                {navItems.map((item, index) => (
                    // item.name === 'COMPANYs' ?

                    //     <Box>
                    //         <Button endIcon={<KeyboardArrowDownIcon />} style={{ color: Color.black, fontFamily: 'Be Vietnam Pro', fontWeight: 400 }} onClick={handleClickCompany} >
                    //             {item.name}
                    //         </Button>
                    //         {isClick && (
                    //             <List key={index}>

                    //                 {
                    //                     companyItems.map((companyItem) => (
                    //                         <ListItem key={companyItem.name}>
                    //                             <ListItemButton sx={{ pl: 4 }}>
                    //                                 <ListItemText primary={companyItem.name} onClick={() => {
                    //                                     window.scrollTo(0, 0);
                    //                                     setIsclick(!isClick)
                    //                                     navigate(companyItem.nav);

                    //                                 }}
                    //                                 />
                    //                             </ListItemButton>
                    //                         </ListItem>
                    //                     ))
                    //                 }
                    //             </List>
                    //         )}
                    //     </Box>
                        /*{<div>
                             <ListItemButton onClick={handleClick}>
     
                                 <ListItemText primary={item.name} />
                                 {open ? <ExpandLess /> : <ExpandMore />}
                             </ListItemButton>
                             <Collapse in={open} timeout="auto" unmountOnExit>
                             <List component="div" disablePadding>
                                 
                                 {companyItems.map((companyItem) => (
                                     <ListItem key={companyItem.name}>
                                     <ListItemButton sx={{ pl: 4 }}>
                                                             <ListItemText primary={companyItem.name} onClick={() => {
                                                                 window.scrollTo(0, 0);
                                                                 navigate(companyItem.nav);
                                                                 
                                                             }}
                                                             />
                                                             </ListItemButton>
                                                             </ListItem>
                                                         ))}
                                     
                                 
                             </List>
                         </Collapse>
                         </div>}*/
                        // :
                        <ListItem key={item.name}>
                            <ListItemButton sx={{ textAlign: 'center', fontWeight: 400 }}>
                                <StyledListItemText primary={item.name} onClick={() => {
                                    window.scrollTo(0, 0);
                                    navigate(item.nav)
                                }} />
                            </ListItemButton>
                        </ListItem>
                ))}

            </List>
        </Box>
    );

    return (
        <Box sx={{ display: "flex" }}>

            <AppBar component="nav" style={{ backgroundColor: 'white', boxShadow: 'none' }}>
                <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon style={{ color: Color.black }} />
                    </IconButton>
                    <img src={LOGO} style={{ width: '60px', marginLeft: '10%' }} alt={'logo'} />
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        {navItems.map((item, index) => (
                            // item.name === 'COMPANY' ?
                            //     <Button >

                            //         <div class="menu" >
                            //             <Button endIcon={<KeyboardArrowDownIcon />} style={{ color: Color.black, fontFamily: 'Be Vietnam Pro', fontWeight: 400 }}  >
                            //                 {item.name}</Button>
                            //             <ul class="menu-dropdown">

                            //                 {companyItems.map((companyItem) => (
                            //                     <li key={index} onClick={() => {
                            //                         window.scrollTo(0, 0);
                            //                         navigate(companyItem.nav);

                            //                     }}
                            //                     >{companyItem.name}</li>
                            //                 ))}


                            //             </ul>
                            //         </div>
                            //     </Button>

                            //     :

                                <Button style={{ color: Color.black, fontFamily: 'Be Vietnam Pro', fontWeight: 400 }} key={item.name}
                                    onClick={() => {
                                        window.scrollTo(0, 0);
                                        navigate(item.nav)
                                    }} children={item.name} />
                        ))}
                    </Box>


                    <ColorButton onClick={() => {
                            window.scrollTo(0, 0);
                            window.open("https://buy.stripe.com/dR65lqbCp6at2l23cd", "_blank", "noreferrer");
                        }} variant="contained" sx={{ marginRight: { xs: '1%', md: '10%' } }}>
                        <img src={LyxButtonLOGO} style={{ marginRight: 10, scale: '70%' }} alt={'logo'} />
                        PAY</ColorButton>

                </Toolbar>

            </AppBar>
            <Box component="nav" style={{ backgroundColor: Color.theme }}>
                <Drawer
                    variant="temporary"
                    open={mobileOpen || isClick}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240, backgroundColor: Color.theme },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </Box>
    );
}

export default Header;
