import * as React from 'react';
import './css/career.css';
import { COLORS as Color } from "../Common/Constant/Color";
import bag from "../assets/img/careers/bag.png";
import { Grid, styled, Typography } from "@mui/material";
import 'react-phone-input-2/lib/style.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const GradientTextSpan = styled('span')({
    background: '-webkit-linear-gradient(0deg, #9430F2 0%, #7669EF 25%, #51B2ED 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
});


const Career = () => {
    return (
        <div style={{
            fontSize: ' calc(10px + 2vmin)',
            fontFamily: 'Helvetica',
            marginTop: '80px',
            color: Color.black
        }}>
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center" spacing={1} style={{ marginTop: "20px" }}>

                <Grid item xs={12}>
                    <div style={{ textAlign: 'center', padding: '0 10%' }}>

                        <Typography sx={{ fontSize: {xs:30,md:40}, fontWeight:700 }}><GradientTextSpan>Careers At LYXUX</GradientTextSpan></Typography>
                        <Typography sx={{ color: Color.headingColor, fontSize: {xs:15,md:23}, textTransform: 'capitalize', }}>At LYXUX, We Believe in empowering our employees to grow and succeed. <GradientTextSpan>Our team is our greatest asset</GradientTextSpan>, and we are always looking for <GradientTextSpan>talented, driven individuals</GradientTextSpan> to join us.</Typography>

                    </div>
                </Grid>
            </Grid>
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center" spacing={1} style={{ marginTop: "20px" }}>
                <Grid item xs={10} sm={6}>
                    <Card >

                        <CardContent sx={{textTransform:'capitalize'}}>
                            <img style={{ width: "80px" }} src={bag} alt="" />
                            <p>
                                <b> we are sorry.....we are not hiring at the moment.</b>
                            </p>
                            <p>Feel free to reach out to our HR team at <u style={{cursor:'pointer',textTransform: 'none'}} onClick={() => {
                                        window.location.href = `mailto:info@lyxux.com?`;
                                    }}>info@lyxux.com</u> with any questions or to express your interest in future opportunities.</p>
                        </CardContent>

                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}
const ButtonStyle = {
    backgroundColor: Color.theme,
    margin: '5px 0 20px 0',
    color: Color.white,
    fontFamily: 'Baskervville',
    fontSize: 15,
    padding: 15,
    borderRadius: 100
};


export default Career;
