import { Box, Button, CardMedia, Container, Grid, Icon, Stack, styled, Typography } from "@mui/material";
import WorkIcon from '@mui/icons-material/Work';
import PlaceIcon from '@mui/icons-material/Place';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import HomeIcon from '@mui/icons-material/Home';

import { FONTS } from "../Common/Constant/Font";
import bgIcon2 from '../assets/img/careers/bgIcon2.png';
import bgIcon1 from '../assets/img/careers/bgIcon1.png';
import { COLORS } from "../Common/Constant/Color";
import { useNavigate } from "react-router-dom";


const GradientTextSpan = styled('span')({
    background: '-webkit-linear-gradient(0deg, #7669EF 0%, #7669EF 25%, #51B2ED 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
});

const dataset = [
    {
        topic: 'Creative And UI Designer',
        main: 'United Kingdom',
        companyLocation: 'Colombo',
        workingTime: 'Full-Time',
        workingStyle: 'Hybrid',
    },
    {
        topic: 'Creative And UI Designer',
        main: 'United Kingdom',
        companyLocation: 'Colombo',
        workingTime: 'Full-Time',
        workingStyle: 'Hybrid',
    },
    {
        topic: 'Creative And UI Designer',
        main: 'United Kingdom',
        companyLocation: 'Colombo',
        workingTime: 'Full-Time',
        workingStyle: 'Hybrid',
    },
    {
        topic: 'Creative And UI Designer',
        main: 'United Kingdom',
        companyLocation: 'Colombo',
        workingTime: 'Full-Time',
        workingStyle: 'Hybrid',
    }
]

const CareersPage = () => {
    const navigate = useNavigate();
    return (
        <Box sx={{ mt: 20, mb:-10 }}>
            <Container>
                <Typography sx={{
                    fontSize: { xs: 'auto', md: '18px' },
                    fontFamily: FONTS.Helvetica
                }}>
                    At LYXUX, We Believe In Empowering Our Employees To Grow And Succeed.
                    <GradientTextSpan> Our Team Is Our Greatest Asset</GradientTextSpan>, And We Are Always Looking For
                    <GradientTextSpan> Talented, Triven Individuals</GradientTextSpan> To Join Us.
                </Typography>


                <Grid container spacing={0} alignItems="center">
                    <Grid item xs={12} md={2}>
                        <CardMedia
                            component="img"
                            image={bgIcon2}
                            sx={{
                                width: "150px",
                                height: 'auto',
                                mt: { xs: '0px', md: '-200px' }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={10} sx={{ mt: { xs: 'auto', md: '100px' } }}>
                        {dataset.map((data, index) => (
                            <Box sx={{
                                width: '100%',
                                borderRadius: '10px',
                                backgroundColor: COLORS.white,
                                boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
                                p: 2,
                                mt: '20px'
                            }}>
                                <Stack direction={{ xs: "column", md: "row" }} justifyContent="space-between" alignItems="center">
                                    <Box sx={{ width: '80%' }}>
                                        <Box sx={{ width: '100%', textAlign: { xs: 'center', md: 'left' } }}>
                                            <Typography sx={{
                                                fontSize: '20px',
                                                fontWeight: 700,
                                                fontFamily: FONTS.Helvetica
                                            }}>{data.topic}</Typography>
                                        </Box>
                                        <Stack direction={{ xs: "column", md: "row" }} spacing={'auto'} >
                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                gap: '5px'
                                            }}>
                                                <Box>
                                                    <Icon>
                                                        <WorkIcon />
                                                    </Icon>
                                                </Box>
                                                <Box>
                                                    <Typography sx={{
                                                        fontFamily: FONTS.Helvetica,
                                                        fontSize: '15px',
                                                        color: COLORS.headingColor,
                                                        fontWeight: 400
                                                    }}>{data.main}</Typography>
                                                </Box>
                                            </Box>

                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                gap: '5px'
                                            }}>
                                                <Box>
                                                    <Icon>
                                                        <PlaceIcon />
                                                    </Icon>
                                                </Box>
                                                <Box>
                                                    <Typography sx={{
                                                        fontFamily: FONTS.Helvetica,
                                                        fontSize: '15px',
                                                        color: COLORS.headingColor,
                                                        fontWeight: 400
                                                    }}>{data.companyLocation}</Typography>
                                                </Box>
                                            </Box>
                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                gap: '5px'
                                            }}>
                                                <Box>
                                                    <Icon>
                                                        <WatchLaterIcon />
                                                    </Icon>
                                                </Box>
                                                <Box>
                                                    <Typography sx={{
                                                        fontFamily: FONTS.Helvetica,
                                                        fontSize: '15px',
                                                        color: COLORS.headingColor,
                                                        fontWeight: 400
                                                    }}>{data.workingTime}</Typography>
                                                </Box>
                                            </Box>
                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                gap: '5px'
                                            }}>
                                                <Box>
                                                    <Icon>
                                                        <HomeIcon />
                                                    </Icon>
                                                </Box>
                                                <Box>
                                                    <Typography sx={{
                                                        fontFamily: FONTS.Helvetica,
                                                        fontSize: '15px',
                                                        color: COLORS.headingColor,
                                                        fontWeight: 400
                                                    }}>{data.workingStyle}</Typography>
                                                </Box>
                                            </Box>
                                        </Stack>
                                    </Box>
                                    <Button sx={{
                                        width: '132px',
                                        height: '23px',
                                        borderRadius: '60px',
                                        padding: '10px',
                                        background: 'linear-gradient(90deg, rgba(148, 48, 242, 1) 0%, rgba(118, 105, 239, 1) 5%, rgba(81, 178, 237, 1) 100%)',
                                        fontSize: '12px',
                                        fontFamily: FONTS.Helvetica,
                                        color: COLORS.white,
                                        transition: 'background 0.3s ease, transform 0.3s ease',

                                        '&:hover': {
                                            background: 'linear-gradient(90deg, rgba(118, 105, 239, 1) 0%, rgba(148, 48, 242, 1) 5%, rgba(81, 178, 237, 1) 100%)',
                                            transform: 'scale(1.05)',
                                        },

                                    }}
                                    onClick={()=>navigate('/View&Apply')}
                                    >View And Apply</Button>
                                </Stack>
                            </Box>
                        ))}
                    </Grid>
                </Grid>

            </Container>
            <Box sx={{
                    width:'100%',
                    display:'flex',
                    justifyContent:'right'
                }}>
                    <img src={bgIcon1} style={{scale:'50%'}}/>
                </Box>
        </Box>
    );
}

export default CareersPage;
