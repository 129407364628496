import React from 'react';
import { Button, Grid, Container } from "@mui/material";
import { COLORS as Color } from "../Constant/Color";
import { useNavigate } from "react-router-dom";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { styled } from '@mui/material/styles';
import brand1 from "../../assets/img/home/ImrookaLogo.jpg";
import brand2 from "../../assets/img/home/KadawathaHomes.jpg";
import brand3 from "../../assets/img/home/crystalClear.png";
import brand4 from "../../assets/img/home/Yegabiyo.jpg";
import brand5 from "../../assets/img/home/esbLogo.png";
import brand6 from "../../assets/img/home/ImrookaSpecieLogo.jpg";



const ClientCards = () => {
    const brands = [
        {
            url: brand1,
            title: 'brand1',
            width: '20%',
        },
        {
            url: brand2,
            title: 'brand2',
            width: '20%',
        },
        {
            url: brand3,
            title: 'crystalClear',
            width: '20%',
        },
        {
            url: brand4,
            title: 'brand5',
            width: '20%',
        },
        {
            url: brand5,
            title: 'brand5',
            width: '20%',
        },
        {
            url: brand6,
            title: 'brand6',
            width: '20%',
        },
    ];
    return (
        <Container style={{
            marginBottom: 10, width: '100%',
        }}>
            <Grid container spacing={0}>
                {brands.map((image, index) => (
                    <Grid
                        item
                        sx={{
                            border: "1px solid #0090ff",
                            padding: 5,
                            opacity: 0.5,
                            transition: 'opacity 0.3s ease',
                            '&:hover': {
                                opacity: 1,
                            },
                        }}
                        xs={12} sm={6} md={4}
                        key={index}
                    >
                        <img style={{ width: "50%", borderRadius: image.title !== 'crystalClear' ? 100 : 0 }} src={image.url} alt="" />
                    </Grid>
                ))}


            </Grid>


        </Container>
    );
};


export default ClientCards;
