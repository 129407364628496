// import React, { useEffect } from 'react';

// const CalendlyWidget = () => {
//   useEffect(() => {
//     const script = document.createElement('script');
//     script.src = 'https://assets.calendly.com/assets/external/widget.js';
//     script.async = true;
//     document.body.appendChild(script);
    
//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

//   return (
//     <div 
//       className="calendly-inline-widget" 
//       data-url="https://calendly.com/lyxuxltd/30min?primary_color=9430f2" 
//       style={{ minWidth: '320px', height: '700px' }}>
//     </div>
//   );
// };

// export default CalendlyWidget;

import React, { useEffect } from 'react';

const CalendlyWidget = () => {
  useEffect(() => {
    // Load the Calendly widget script
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    // document.body.appendChild(script);

    script.onload = () => {
      // Initialize the Calendly badge widget after the script has loaded
      window.Calendly.initBadgeWidget({
        url: 'https://calendly.com/lyxuxltd/lyxux',
        text: 'Schedule time with Us',
        color: '#ac00ff',
        textColor: '#ffffff',
        branding: undefined,
      });
    };

    return () => {
      // Cleanup: remove the script if the component is unmounted
      document.body.removeChild(script);
    };
  }, []);

  return (
    <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet" />
  );
};

export default CalendlyWidget;
