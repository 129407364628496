import { Box, Button, Stack, styled, TextField, Typography } from "@mui/material"
import { FONTS } from "../Common/Constant/Font";
import { COLORS } from "../Common/Constant/Color";
import { Topic } from "@mui/icons-material";
import { type } from "@testing-library/user-event/dist/type";
import { useState } from "react";

const GradientTextSpan = styled('span')({
    background: '-webkit-linear-gradient(90deg, #7669EF 0%, #7669EF 25%, #51B2ED 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
});

const applyData = [
    {
        Topic: 'Creative And UI Designer',
        descreaption: 'Are you a visionary in both aesthetics and user experience? We are on the hunt for a multi-talented Creative + UI Designer  to elevate our digital presence!',
        points: [
            'Proven experience as a Designer with a strong portfolio showcasing your work.',
            'Proficiency in Adobe XD, Adobe Photoshop, and Adobe Illustrator',
            'Solid understanding of design principles, UI design, and graphic design',
            '3 years experience in creating user-friendly and visually appealing designs'
            
        ],
        responsibility: [
            'Develop and execute design concepts for web, mobile, and digital platforms.',
            'Develop and execute design concepts for web, mobile, and digital platforms.',
            'Develop and execute design concepts for web, mobile, and digital platforms.',
            'Develop and execute design concepts for web, mobile, and digital platforms.'
        ]
    }
];


const ViewAndApplyPage = () => {

    const [fileName, setFileName] = useState(null)
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        

      }
    return(
        <Box sx={{mt:15, mx:5}}>
            <Stack direction={{xs:"column", md:'row'}} spacing={2} sx={{width:'100%'}}>
                <Box sx={{width:{xs:'100%',md:'50%'}}}>
                    <Box sx={{width:'100%', textAlign:{xs:'center',md:'left'}}}>
                        <Typography sx={{
                            fontSize:{xs:'20px', md:'45px'},
                            fontWeight:700,
                            fontFamily:FONTS.Helvetica
                        }}>We're Looking For A Full Time</Typography>
                        <Typography sx={{
                            fontSize:{xs:'40px', md:'85px'},
                            fontWeight:700,
                            fontFamily:FONTS.Helvetica
                        }}><GradientTextSpan>Creative and UI Designer</GradientTextSpan></Typography>
                    </Box>
                    <Box sx={{width:'100%', textAlign:'left'}}>
                        <Typography sx={{
                            fontSize:{xs:'20px', md:'40px'},
                            fontWeight:400,
                            color:COLORS.lightBlue,
                            fontFamily:FONTS.Helvetica
                        }}>Are You The Right Fit?</Typography>
                        <Box>
                            {applyData.map((data,index)=>(
                                <Box>
                                    <Typography>{data.descreaption}</Typography>
                                    {data.points.map((points,pointIndex)=>(
                                        <ul>
                                            <li>{points}</li>
                                        </ul>
                                    ))}
                                </Box>
                            ))}
                        </Box>
                        
                    </Box>

                    <Box sx={{width:'100%', textAlign:'left',mt:'40px'}}>
                        <Typography sx={{
                            fontSize:{xs:'20px', md:'40px'},
                            fontWeight:400,
                            color:COLORS.lightBlue,
                            fontFamily:FONTS.Helvetica
                        }}>Your Role And Responsibilities</Typography>
                        <Box>
                            {applyData.map((data,index)=>(
                                <Box>
  
                                    {data.responsibility.map((points,pointIndex)=>(
                                        <ul>
                                            <li>{points}</li>
                                        </ul>
                                    ))}
                                </Box>
                            ))}
                        </Box>
                        
                    </Box>
                </Box>
                <Box sx={{width:{xs:'100%',md:'50%'}}}>
                    <Box sx={{
                        width:'100%'
                    }}>
                    <TextField id="outlined-basic" label="Your Name" variant="outlined" sx={{
                        width:'100%',
                        height:'46px',
                        borderRadius:'5px',
                        mb:'20px'

                    }}/>
                    <TextField id="outlined-basic" label="Your Email" variant="outlined" sx={{
                        width:'100%',
                        height:'46px',
                        borderRadius:'5px',
                        mb:'20px'

                    }}/>
                    <TextField id="outlined-basic" label="Position" variant="outlined" sx={{
                        width:'100%',
                        height:'46px',
                        borderRadius:'5px',
                        mb:'20px'

                                    }}/>
                        <TextField
                            id="outlined-multiline-static"
                            label="Enter Your Message Here"
                            multiline
                            rows={4}
                            sx={{
                                width:'100%',
                                borderRadius:'5px',
                                mb:'20px'

                            }}
                        />
                        <Typography sx={{
                            fontFamily:'Inter',
                            fontWeight:300,
                            fontSize:'15px',
                            color:COLORS.headingColor,
                            textAlign:'left'
                        }}>Attach your CV here: Accepted file formats are (DOC, DOCX, PDF, PPT, PPTX)</Typography>

                        <Box sx={{
                           width:'100%',
                           display:'flex',
                           justifyContent:'left',
                           alineItem:'center'
                        }}>
                            <input
                                accept=".doc,.docx,.pdf,.ppt,.pptx"
                                style={{ display: 'none' }}
                                id="file-upload"
                                type="file"
                                onChange={handleFileChange}
                             />
                            <label htmlFor="file-upload">
                                <Button  component="span" sx={{
                                    width:'103px',
                                    height:'28px',
                                    border:'0.5px solid #000000',
                                    borderRadius:'0.5px',
                                    backgroundColor:COLORS.white,
                                    color:COLORS.buttonBlack,
                                    fontSize:'10px',
                                    transform:'none',
                                    '&:hover': {
                                        backgroundColor: COLORS.white,
                                        transform: 'scale(1.005)',
                                    },
                                }}>
                                    Choose File
                                </Button>
                            </label>
                        {/* {fileName ? (
                            <Typography>{fileName}</Typography>
                        ):(
                            <Typography>No file Chosen</Typography>
                        )} */}

                        </Box>
                        
                        <Box sx={{width:'100%'}}>
                            <Button sx={{
                                width:'100%',
                                height:'51px',
                                borderRadius:'5px',
                                backgroundColor:COLORS.buttonBlack,
                                mt:'20px',
                                color:COLORS.white,
                                transition: 'background 0.3s ease, transform 0.3s ease',

                                '&:hover': {
                                    backgroundColor: COLORS.black,
                                    transform: 'scale(1.005)',
                                },
                            }}>Send Now</Button>
                        </Box>
                    </Box>
                </Box>
            </Stack>
        </Box>
    )
};
export default ViewAndApplyPage;
