import * as React from 'react';
import { Grid, Container, Button, Typography } from "@mui/material";
import team from "../assets/img/team/team.png";
import { COLORS as Color } from "../Common/Constant/Color";
import { styled } from '@mui/material/styles';
import ReactCardFlip from "react-card-flip";

import './css/teamStyle.scss';

const Team = () => {

    const [hoverIndex, setHoverIndex] = React.useState(0);
    const [flip, setFlip] = React.useState(false);
    const Teams = [
        {
            url: team,
            title: 'finance',
            name: 'Mr.Fernando',
            description: 'Innovation & passion and precision',
            width: '20%',
        },
        {
            url: team,
            title: 'finance',
            name: 'Mr.Fernando',
            description: 'Innovation & passion and precision',
            width: '20%',
        },
        {
            url: team,
            title: 'finance',
            name: 'Mr.Fernando',
            description: 'Innovation & passion and precision',
            width: '20%',
        },
        {
            url: team,
            title: 'finance',
            name: 'Mr.Fernando',
            description: 'Innovation & passion and precision',
            width: '20%',
        },
        {
            url: team,
            title: 'finance',
            name: 'Mr.Fernando',
            description: 'Innovation & passion and precision',
            width: '20%',
        },
        {
            url: team,
            title: 'finance',
            name: 'Mr.Fernando',
            description: 'Innovation & passion and precision',
            width: '20%',
        },
        {
            url: team,
            title: 'finance',
            name: 'Mr.Fernando',
            description: 'Innovation & passion and precision',
            width: '20%',
        },
        {
            url: team,
            title: 'finance',
            name: 'Mr.Fernando',
            description: 'Innovation & passion and precision',
            width: '20%',
        },

        {
            url: team,
            title: 'finance',
            name: 'Mr.Fernando',
            description: 'Innovation & passion and precision',
            width: '20%',
        },
        {
            url: team,
            title: 'finance',
            name: 'Mr.Fernando',
            description: 'Innovation & passion and precision',
            width: '20%',
        },
        {
            url: team,
            title: 'finance',
            name: 'Mr.Fernando',
            description: 'Innovation & passion and precision',
            width: '20%',
        },

    ];
    const ColorButton = styled(Button)(({ theme }) => ({
        fontFamily: 'Inter',
        fontSize: 20,
        textTransform: 'uppercase',
        fontWeight: 700,
        borderRadius: 5,
        padding: '8px 25px',
        backgroundImage: `linear-gradient(to right, ${Color.purple},${Color.grayBlue},${Color.lightBlue})`,
        // backgroundColor: purple[500],
        // '&:hover': {
        //     backgroundColor: purple[700],
        // },
    }));
    return (
        <div style={{
            fontSize: ' calc(10px + 2vmin)',
            fontFamily: 'Helvetica',
            marginTop: '150px',
            color: Color.black
        }}>
            <Container>
                <h1 className="gradient-text" style={{ fontSize: 50, }}>Meet Our Family</h1>
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center" spacing={1} style={{ marginTop: "20px" }}>
                    {Teams.map((vector, index) => (
                        <Grid key={index} item xs={6} sm={4} md={3}>
                            <div style={{ textAlign: 'center', padding: '0 10%' }} className=''>
                                {/* <ReactCardFlip isFlipped={flip && hoverIndex === index} flipDirection="horizontal"> */}
                                <div className="card">
                                    <div
                                        className="cover"
                                        onMouseEnter={() => {
                                            setHoverIndex(index);
                                            setFlip(true);
                                        }}
                                        onMouseLeave={() => {
                                            setHoverIndex(null);
                                            setFlip(false);
                                        }}
                                        style={{ backgroundImage: `url(${vector.url})`, width: '100%', height: '250px', borderRadius: '10px' }}
                                    >
                                        <div className="back">

                                            <p>{vector.description}</p>
                                        </div>
                                    </div>


                                </div>
                                <p style={{ textTransform: 'capitalize', fontWeight: 600, marginBottom: 0, }}>{vector.title}</p>
                                <p style={{ textTransform: 'capitalize', fontSize: 16, marginTop: 1 }}>{vector.name}</p>
                            </div>
                        </Grid>
                    ))}

                </Grid>
            </Container>

            <Grid>
                <Grid style={{ paddingBottom: '20px', marginTop: '100px' }}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={5}
                    >
                        <Grid item>
                            {/* <AnimationOnScroll animateIn="animate__fadeInRight"> */}
                            <h1 style={{ color: Color.black, fontSize: 40, }}>JOIN OUR LYXUX FAMILY</h1>

                            <ColorButton variant="contained"  >Join Our Family</ColorButton>

                            {/* </AnimationOnScroll> */}


                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </div>
    )
}
export default Team;
