import * as React from 'react';
import { Grid,   Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import ClientCards from "../Common/Component/ClientCards";
import './css/teamStyle.scss';

const GradientTextSpan = styled('span')({
    background: '-webkit-linear-gradient(0deg, #9430F2 0%, #7669EF 25%, #51B2ED 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
});

const Clients = () => {
   
    return (
        <Grid>
            <Grid style={{ paddingBottom: '20px', marginTop: '100px' }}>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={5}
                >
                    <Grid item>
                        {/* <AnimationOnScroll animateIn="animate__fadeInRight"> */}
                        <Typography sx={{ fontSize: { xs: 40, md: 50 }, fontWeight: 700 }}><GradientTextSpan>Our Clients</GradientTextSpan></Typography>
                        <p >We understand that each customer is unique, and we tailor our services to address their specific challenges and aspirations.</p>
                        {/* </AnimationOnScroll> */}


                    </Grid>
                </Grid>
                {/* <AnimationOnScroll animateIn="animate__fadeIn" style={{width: '100%'}}> */}
                <ClientCards/>
                {/* </AnimationOnScroll> */}
            </Grid>
        </Grid>
    )
}
export default Clients;
